<template>
  <base-header :title="title" :error="error">
    <div class="section-header__button-container" slot="buttons">
      <a-button
        :disabled="!canSaveArrangement"
        @click="$emit('save-arrangement')"
        :data-test="'button.save'"
        :color="saveButtonColor"
      >
        <a-icon name="save" />
        {{ $t('generic.buttons.save') }}
      </a-button>

      <custom-button
        size="default"
        :color="'blue'"
        :icon="'group'"
        :variant="'subtle'"
        :label="$t('generic.buttons.connect')"
        @click="openConnectModal"
        :disabled="!canLinkArrangement"
        :data-test="'button.connect'"
      />
    </div>
  </base-header>
</template>

<script>
  import BaseHeader from '../Header'
  import CustomButton from '../../user-interface/Button'
  import { EventBus, MODAL_OPEN_EVENT } from '../../../common/EventBus'
  import { Prompt } from '../modal/ModalContent'
  import { store } from '../../../store'

  export default {
    components: {
      BaseHeader,
      CustomButton
    },
    props: {
      title: String,
      arrangement: Object,
      error: String,
      isSaving: Boolean
    },
    data () {
      return {
        store,
        canLinkArrangement: this.arrangement.type === 'userdefined'
      }
    },
    computed: {
      canSaveArrangement () {
        const arrangementStore = this.store.getCurrentArrangementStore()
        return null !== arrangementStore && arrangementStore.canSave() && store.state.changesPending && this.currentArrangementHasItems
      },
      saveButtonColor () {
        return this.canSaveArrangement ? 'blue' : 'grey'
      },
      currentArrangementHasItems () {
        return store.currentArrangement &&
          store.currentArrangement.items.length > 0
      }
    },
    methods: {
      openConnectModal () {
        EventBus.$emit(MODAL_OPEN_EVENT, Prompt.connect(this.arrangement, false))
      }
    }
  }
</script>
