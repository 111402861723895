var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dataIsLoaded
    ? _c(
        "vue-draggable",
        {
          staticClass: "learning-items-container-outer-wrapper pr-3",
          attrs: {
            tag: "div",
            "ghost-class": "learning-item--dragging-placeholder",
            draggable: ".learning-item",
            list: _vm.arrangementStore.arrangement.items,
            group: {
              name: "learningItems",
              put: true,
              pull: true,
            },
            "remove-on-spill": true,
            "on-spill": _vm.onSpill,
          },
          on: {
            change: function ($event) {
              return _vm.onListChange()
            },
          },
        },
        [
          _c(
            "transition-group",
            {
              ref: "itemsContainer",
              staticClass:
                "learning-items-container learning-items-container--arrangement",
              class: {
                "learning-items-container--empty":
                  _vm.arrangementStore.arrangement.items.length === 0,
              },
              attrs: {
                tag: "div",
                "data-empty-items-message": _vm.$t(
                  "detail.items.empty_message"
                ),
                "data-test": "learning-items-container",
                name: "fade",
              },
            },
            _vm._l(_vm.arrangementStore.arrangement.items, function (item) {
              return _c("learning-item", {
                key: "item-" + item.contentIdentifier,
                ref: "itemComponents",
                refInFor: true,
                attrs: {
                  item: item,
                  "is-in-arrangement": true,
                  draggable: true,
                  "is-available": _vm.isAvailable(item),
                  "show-arrange-button":
                    _vm.arrangementStore.arrangeParagraphEnabled,
                },
                on: { "switch-list": _vm.removeFromArrangement },
              })
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }