const currentSchoolYear = (new Date()).getFullYear() - ((new Date()).getMonth() < 7 ? 1 : 0);

const groupIsCurrent = group => {
  if (null === group.schoolYear) {
    return true;
  }

  return Number(group.schoolYear.substr(0, 4)) >= currentSchoolYear;
}

class Groups {
  constructor (groups) {
    this.groups = groups
    this.length = this.groups.length
    this.lengthCurrent = this.available([]).length
  }

  has (groupId) {
    return this.groups.findIndex(g => g.id === groupId) !== -1
  }

  get (groupId) {
    return this.groups.find(g => g.id === groupId)
  }

  getByIds (groupIds) {
    return this.groups.filter(group => groupIds.includes(group.id))
  }

  without (groupIds) {
    return this.groups.filter(group => !groupIds.includes(group.id))
  }

  available (selectedGroupIds) {
    return this.groups.filter(group => !selectedGroupIds.includes(group.id) && groupIsCurrent(group))
  }
}

export default Groups
