import { routePost } from '../util/request'

class RoleService {

  async switchRole () {
    return await routePost('arrange_switch_role')
  }
}

export default RoleService
