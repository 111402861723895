var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { ref: "arrangement-action", staticClass: "pr-3 mb-3 a-modal-content" },
        [
          _c(
            "div",
            { staticClass: "confirmation-container" },
            [
              _c("p", {
                staticClass: "feedback feedback--regular",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("generic.modal.messages.delete_arrangement", {
                      arrangement: this.arrangement.title,
                    })
                  ),
                },
              }),
              _vm._v(" "),
              _c("a-alert", { attrs: { type: "warning" } }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("generic.modal.messages.delete_arrangement_warning")
                  )
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.error
            ? _c("a-alert", { attrs: { type: "error" } }, [
                _vm._v(_vm._s(_vm.error)),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal-button-footer",
        [
          _c(
            "custom-button",
            {
              attrs: {
                size: "medium",
                color: "red",
                disabled: _vm.loading,
                loading: _vm.loading,
                "data-test": "modal.button.confirm.delete",
              },
              on: { click: _vm.checkDeleteArrangement },
            },
            [
              _c("a-icon", { attrs: { name: "delete" } }),
              _vm._v(
                "\n      " + _vm._s(_vm.$t("generic.buttons.delete")) + "\n    "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "custom-button",
            {
              attrs: {
                size: "medium",
                color: "blue",
                variant: "ghost",
                disabled: _vm.loading,
              },
              on: { click: _vm.cancel },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("generic.buttons.cancel")) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }