<template>
  <div>
    <div class="modal-loading-overlay modal-loading-overlay--fade"></div>
    <div class="modal-loading-overlay">
      <a-loader />
    </div>
  </div>
</template>

<script>
  export default {}
</script>
