var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !!_vm.selectedFilters.length
    ? _c("div", { staticClass: "selected-filters-container pl-2 pr-4" }, [
        _c(
          "a",
          {
            staticClass: "selected-filters-clear-trigger flexbox mb-2",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.resetAllFilters.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("list.clear_filters")))]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "selected-filters__inner" },
          _vm._l(_vm.selectedFilters, function (filter) {
            return _c("pill", {
              key: filter.id,
              staticClass: "selected-filters__item mb-1",
              attrs: {
                id: filter.id,
                label: filter.title,
                value: filter.value,
              },
              on: {
                deselect: function ($event) {
                  return _vm.clearFilterValue(filter)
                },
              },
            })
          }),
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }