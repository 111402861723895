'use strict'

import i18n from '../../../common/i18n'
import ConnectArrangement from '../detail/ConnectArrangement'
import DeleteArrangement from '../detail/DeleteArrangement'
import DuplicateArrangement from '../detail/DuplicateArrangement'
import EditArrangement from '../detail/EditArrangement'
import ModalContextMenu from './ModalContextMenu'
import ModalNewArrangement from './ModalNewArrangement'
import ModalConfirm from './ModalConfirm'
import ModalConfirmLoseChanges from './ModalConfirmLoseChanges'
import ModalLearningItemDetail from './ModalLearningItemDetail'
import ModalArrangeContent from './ModalArrangeContent'
import FixArrangement from '../detail/FixArrangement'

const ACTION_CONNECT = 'connect'
const ACTION_DELETE = 'delete'
const ACTION_DUPLICATE = 'duplicate'
const ACTION_EDIT = 'edit'

export const ModalType = {
  LARGE: 'large',
  PROMPT: 'prompt',
  WIDE: 'wide',
  DEFAULT: ''
}

export class ModalContent {
  constructor (component, title, arrangement, icon = { file: 'file-lines', width: '26px' }) {
    this.component = component
    this.title = title
    this.icon = icon
    this.props = {
      arrangement: arrangement,
      modalType: ModalType.DEFAULT
    }
  }
}

export class ContextMenu {
  constructor (modalContent) {
    this.modalContent = modalContent
  }

  static allActions (title, arrangement, icon) {
    const content = new ModalContent(ModalContextMenu, title, arrangement, icon)
    if (arrangement.type === 'userdefined') {
      content.props.actions = [ACTION_EDIT, ACTION_DUPLICATE, ACTION_CONNECT, ACTION_DELETE]
    } else {
      content.props.actions = [ACTION_EDIT, ACTION_DUPLICATE, ACTION_DELETE]
    }
    return new ContextMenu(content)
  }
}

export class Prompt {
  constructor (modalContent) {
    this.modalContent = modalContent
  }

  static connect (arrangement) {
    const content = new ModalContent(ConnectArrangement, i18n.t('generic.context_menu.connect.label'), arrangement)
    return new Prompt(content)
  }

  static delete (arrangement) {
    const content = new ModalContent(DeleteArrangement, i18n.t('generic.context_menu.delete.label'), arrangement)
    return new Prompt(content)
  }

  static duplicate (arrangement) {
    const content = new ModalContent(DuplicateArrangement, i18n.t('generic.context_menu.duplicate.label'), arrangement)
    return new Prompt(content)
  }

  static edit (arrangement) {
    const content = new ModalContent(EditArrangement, i18n.t('generic.context_menu.edit.label'), arrangement)
    return new Prompt(content)
  }

  static confirm (title, message, confirmCallback, cancelCallback, forDelete) {
    const content = new ModalContent(ModalConfirm, title)
    content.props.modalType = ModalType.PROMPT
    content.props.content = message
    content.props.confirmCallback = confirmCallback
    content.props.cancelCallback = cancelCallback
    content.props.forDelete = forDelete
    return new Prompt(content)
  }

  static confirmLoseChanges (arrangement) {
    const content = new ModalContent(ModalConfirmLoseChanges, i18n.t('generic.modal.titles.lose_changes'), arrangement)
    content.props.modalType = ModalType.PROMPT
    return new Prompt(content)
  }

  static newArrangement (arrangement) {
    let content;
    if (arrangement.code === '' || arrangement.code === undefined) {
      content = new ModalContent(ModalNewArrangement, i18n.t('generic.new_arrangement'), arrangement, {
        file: 'file',
        width: '26px'
      });
    } else {
      content = new ModalContent(ModalNewArrangement, i18n.t('generic.new_arrangement'), arrangement);
    }
    return new Prompt(content)
  }

  static fixArrangement (arrangement) {
    const icon = { file: 'warning', width: '26px' }

    const content = new ModalContent(FixArrangement, i18n.t('generic.fix_arrangement'), arrangement, icon)
    content.props.modalType = ModalType.WIDE
    return new Prompt(content)
  }
}

export class DetailModal {
  constructor (modalContent, isLarge) {
    this.modalContent = modalContent
    this.isLarge = isLarge
  }

  static learningItemDetail (learningItem) {
    const content = new DetailModalContent(ModalLearningItemDetail, learningItem.title, learningItem, ModalType.DEFAULT)
    return new DetailModal(content, false)
  }

  static learningItemArrangeContent (learningItem) {
    const content = new DetailModalContent(ModalArrangeContent, learningItem.title, learningItem, ModalType.LARGE)
    return new DetailModal(content, true)
  }
}

export class DetailModalContent {
  constructor (component, title, learningItem, modalType, icon = { file: 'file-lines', width: '26px' }) {
    this.component = component
    this.title = title
    this.icon = icon
    this.props = {
      learningItem,
      modalType
    }
  }
}
