<template>
  <div>
    <div class="pr-3 mb-3 a-modal-content" ref="arrangement-action">
      <modal-loading v-if="saving"></modal-loading>
      <div class="fix-arrangement-container">
        <div class="fix-arrangement-header">
          <span class="fix-arrangement-header__label">{{ arrangement.title }}</span>
        </div>
        <div class="fix-arrangement-description" v-html="$t('fix_arrangement.description')"></div>
        <transition-group
          ref="itemsContainer"
          class="learning-items-container learning-items-container--with-replacements"
          tag="div"
          :data-test="'learning-items-container'"
          name="fade"
        >
          <template v-for="invalidItem in arrangementStore.getInvalidItems()">
            <learning-item
              :item="invalidItem.item"
              :key="'item-' + invalidItem.item.contentIdentifier"
              :draggable="false"
              :show-add-remove="false"
              :allow-details="false"
            />
            <div
              class="learning-items-container__icon"
              :key="'item-replacement-icon' + invalidItem.item.contentIdentifier"
            >
              <a-icon class="replacement-icon" name="arrow-right" />
            </div>
            <learning-item
              v-if="null !== invalidItem.replacement"
              :item="invalidItem.replacement"
              :key="'item-replacement-' + invalidItem.item.contentIdentifier"
              :draggable="false"
              :show-add-remove="false"
              :allow-details="false"
            />
            <no-replacement-item
              v-if="null === invalidItem.replacement"
              :key="'item-replacement-' + invalidItem.item.contentIdentifier"
            />
          </template>
        </transition-group>
      </div>
    </div>
    <modal-button-footer>
      <a-button
        :data-test="'fix-arrangement-form.button.submit'"
        @click="fixArrangement"
        color="blue"
        :disabled="saving"
      >
        {{ $t('generic.buttons.fix_arrangement') }}
      </a-button>
      <a-button
        :data-test="'fix-arrangement-form.button.cancel'"
        @click="cancel"
        color="blue"
        variant="ghost"
        :disabled="saving"
      >
        {{ $t('generic.buttons.cancel') }}
      </a-button>
    </modal-button-footer>
  </div>
</template>

<script>
import CustomButton from '../../user-interface/Button'
import ModalButtonFooter from '../modal/ModalButtonFooter'
import ModalLoading from '../modal/ModalLoading'
import LearningItem from './LearningItem'
import NoReplacementItem from './NoReplacementItem'
import {
  EventBus,
  MODAL_CLOSE_EVENT,
  MODAL_REVERSE_SWITCH_EVENT,
  MODAL_DISABLE_EVENT
} from '../../../common/EventBus'
import { store } from '../../../store'
import Warning from '../../../components/notification/Warning'
import { captureException } from "@sentry/vue";

export default {
  components: {
    Warning,
    CustomButton,
    ModalButtonFooter,
    ModalLoading,
    LearningItem,
    NoReplacementItem
  },
  data () {
    return {
      saving: false,
      arrangementStore: store.getCurrentArrangementStore()
    }
  },
  props: {
    arrangement: Object
  },

  created () {
    EventBus.$emit(MODAL_REVERSE_SWITCH_EVENT)
  },

  methods: {
    cancel () {
      EventBus.$emit(MODAL_CLOSE_EVENT)
    },
    async fixArrangement () {
      this.error = null
      this.saving = true
      EventBus.$emit(MODAL_DISABLE_EVENT);
      try {
        await this.arrangementStore.fixArrangement()
      } catch (exception) {
        captureException(new Error(exception.message))
        this.error = exception.message
      } finally {
        this.saving = false
        EventBus.$emit(MODAL_CLOSE_EVENT)
      }
    }
  }
}
</script>
