import { SearchList } from './common/SearchList'
import { FilterFactory } from './common/FilterFactory'

const arrangementFilters = [
  {
    id: 1,
    title: 'leerjaren',
    field: 'schoolYears',
    values: [
      {
        id: 0,
        title: 'Alle leerjaren',
        value: ''
      }
    ]
  }
]

const searchOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    'title'
  ]
}

export class Arrangements {
  static createSearchList (id, arrangements, sortingOptions, updateFiltersOnChange) {
    const filters = FilterFactory.create(arrangements, arrangementFilters)
    return new SearchList(id, arrangements, filters, sortingOptions, searchOptions, updateFiltersOnChange)
  }
}
