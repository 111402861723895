var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-button",
    {
      attrs: {
        size: _vm.size,
        color: _vm.color,
        variant: _vm.variant,
        "data-test": _vm.dataTest,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _vm.icon
        ? _c("a-icon", { attrs: { name: _vm.icon, color: _vm.color } })
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.default ? _vm._t("default") : [_vm._v(_vm._s(_vm.label))],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }