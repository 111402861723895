import { EventBus, MODAL_OPEN_EVENT } from '../../../common/EventBus'
import { Prompt } from './ModalContent'

export function confirm (title, message, confirmCallback, cancelCallback, forDelete) {
  cancelCallback = cancelCallback instanceof Function ? cancelCallback : () => {}
  EventBus.$emit(
    MODAL_OPEN_EVENT,
    Prompt.confirm(title, message, confirmCallback, cancelCallback, forDelete)
  )
}
