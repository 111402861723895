<template>
  <notification-message :notification="notification" />
</template>

<script>
  import Notification from './Notification'
  import NotificationMessage from './NotificationMessage'
  export default {
    components: {
      NotificationMessage
    },
    props: {
      message: {
        type: String
      },
      type: {
        type: String,
        default: 'warning'
      }
    },
    data() {
      return {
        notification: this.getNotification()
      };
    },
    methods: {
      getNotification() {
        if (this.type === 'no-replacement') {
          return Notification.noReplacement(this.message)
        }
        return Notification.warning(this.message);
      }
    }
  }
</script>
