<template>
  <div>
    <div class="pr-3 mb-3 a-modal-content">
      <div class="confirmation-container">
        <p class="feedback feedback--warning">{{ $t('generic.modal.messages.lose_changes') }}</p>
        <p v-if="!hasItems" class="feedback feedback--warning">{{ $t('generic.modal.messages.lose_changes_no_items') }}</p>
        <warning v-if="error" :message="error"/>
      </div>
    </div>
    <modal-button-footer>
      <custom-button size="medium" :color="'blue'" :disabled="!hasItems || isSaving" :loading="isSaving" @click="save">{{ $t('generic.buttons.confirm') }}</custom-button>
      <custom-button size="medium" :color="'blue'" :variant="'subtle'" :disabled="isSaving" @click="dontSave">{{ $t('generic.buttons.dont_save') }}</custom-button>
      <custom-button size="medium" :color="'blue'" :variant="'ghost'" :disabled="isSaving" @click="cancel">{{ $t('generic.buttons.cancel') }}</custom-button>
    </modal-button-footer>
  </div>
</template>

<script>
  import CustomButton from '../../user-interface/Button'
  import ModalButtonFooter from './ModalButtonFooter'
  import { store } from '../../../store'
  import router from '../../../common/router'
  import { EventBus, MODAL_CLOSE_EVENT } from '../../../common/EventBus'
  import { captureException } from "@sentry/vue";

  export default {
    name: 'Confirmation',

    data () {
      return {
        error: null,
        isSaving: false,
        hasItems: this.arrangement.items.length > 0
      }
    },

    props: {
      arrangement: Object
    },

    components: {
      CustomButton,
      ModalButtonFooter
    },

    methods: {
      cancel() {
        EventBus.$emit(MODAL_CLOSE_EVENT)
      },
      dontSave() {
        router.push(store.state.backToRoute).catch(() => {})
      },
      async save() {
        this.error = null
        this.isSaving = true
        try {
          await store.saveArrangementContent(this.arrangement)
          router.push(store.state.backToRoute).catch(() => {})
        } catch (exception) {
          captureException(new Error(exception.message))
          this.error = exception.message
        } finally {
          this.isSaving = false
        }
      }
    }
  }
</script>
