<template>
  <router-view v-if="initialized"/>
</template>

<script>
import { store } from './store'
import i18n from './common/i18n'
import {EventBus} from "./common/EventBus";
import { UnleashClient } from 'unleash-proxy-client';

export default {
  props: {
    method: Object,
    theme: Object,
    groups: Array,
    settings: Object,
    hasAdminLicense: Boolean,
    inAdminMode: Boolean,
    arrangeParagraphEnabled: Boolean,
    authenticatedUserReference: String,
    unleashToken: String,
    unleashApiUrl: String
  },
  data() {
    const unleash = new UnleashClient({
      url: `${this.unleashApiUrl}frontend`,
      clientKey: this.unleashToken,
      appName: 'arrange',
      refreshInterval: 0,
    });

    // TODO: if needed, add more values to the unleash context...
    unleash.setContextField('userReference', this.authenticatedUserReference);

    unleash.start();

    return {
      unleashClient: unleash,
      initialized: false,
      bodyClass: 'arrangements-page' // Set the initial body class
    };
  },
  created () {
    EventBus.$on('toggleAdminMode', isAdminMode => {
      let newClasses = isAdminMode ? 'arrangements-page admin-mode' : 'arrangements-page';
      newClasses = newClasses.split(' ');

      document.body.className = '';
      document.body.classList.add(...newClasses);
    });

    const filters = this.settings.filterFields.map(key => {
      const translationKey = `filters.${key.replace('mergedMetadata.', '')}`
      return {
        key: key,
        title: i18n.t(translationKey)
      }
    });

    const sortingOptions = [
      ...this.settings.sortingOptions.map(sortingOption => {
        return { field: sortingOption, title: i18n.t(`filters.${sortingOption.replace('mergedMetadata.', '')}`) }
      })]

    const arrangeParagraphEnabled = this.arrangeParagraphEnabled ? this.settings.arrangeParagraphEnabled : false

    this.unleashClient.on('ready', evt => {
      store.init(
        this.method,
        this.theme,
        filters,
        this.groups,
        this.settings.itemMetadataKeys,
        sortingOptions,
        this.hasAdminLicense,
        arrangeParagraphEnabled,
        this.authenticatedUserReference,
        this.unleashClient,
        this.inAdminMode
      );

      this.initialized = true;
    });
  }
}
</script>
