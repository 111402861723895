<template>
  <div class="arrangement-filter-panel-container" v-if="showFilterPanel && initialized">
    <arrange-filter-panel :jsonData="filterCategoriesAsJson" :data-test="'filter-panel'" @filterChange="toggleFilter">
      <a-button style="margin: 1rem;" slot="trigger" data-test="filter-panel-button">Open Filter</a-button>
    </arrange-filter-panel>
  </div>
  <div v-else-if="initialized" class="arrangement-filters-accordion mt-2">
    <filter-item v-for="(category, index) in filterCategories" :key="index" :title="category.title" :category="category.field" :options="category.values" />
  </div>
</template>

<script>
  import FilterItem from './FilterItem'
  import { store } from '../../../../store'

  export default {
    props: {
      title: String,
      collapsed: {
        type: Boolean,
        default: true
      },
    },
    computed: {
      filterCategories() {
        return store.arrangementItems.filters
      },
      filterCategoriesAsJson() {
        return JSON.stringify(store.arrangementItems.filters);
      },
      showFilterPanel() {
        return store.showFilterPanel();
      },
      initialized() {
        return store.hasArrangementItems;
      }
    },
    methods: {
      toggleFilter (event) {
        if (event.detail.isChecked === true) {
          store.arrangementItems.filterOn(event.detail.category, {
            type: event.detail.value,
            value: event.detail.value,
            title: event.detail.title
          })
        } else {
          store.arrangementItems.removeFilterValue(event.detail.category, event.detail.value)
        }

        gtag('event', 'search', {
          search_term: event.detail.value,
        });
      }
    },

    components: {
      FilterItem
    }
  }
</script>
