<template>
  <span
    class="pill"
    :class="{ 'pill--large' : large }">{{ label }} <inline-svg class="pill-control" @click.prevent.native="$emit('deselect')" :svg="require('arrange/icon-close.svg')"/>
  </span>
</template>

<script>
  import InlineSvg from '../../components/InlineSvg'

  export default {
    props: {
      id: [ String, Number ],
      label: String,
      value: [ String, Number ],
      large: Boolean
    },

    components: {
      InlineSvg
    }
  }
</script>
