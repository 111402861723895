<template>
  <vue-draggable
    v-if="dataIsLoaded"
    class="learning-items-container-outer-wrapper pr-3"
    tag="div"
    ghost-class="learning-item--dragging-placeholder"
    @change="onListChange()"
    draggable=".learning-item"
    :list="arrangementStore.arrangement.items"
    :group="{
      name: 'learningItems',
      put: true,
      pull: true
    }"
    :remove-on-spill="true"
    :on-spill="onSpill"
  >
    <transition-group
      ref="itemsContainer"
      class="learning-items-container learning-items-container--arrangement"
      :class="{ 'learning-items-container--empty' : arrangementStore.arrangement.items.length === 0 }"
      tag="div"
      :data-empty-items-message="$t('detail.items.empty_message')"
      :data-test="'learning-items-container'"
      name="fade">
      <learning-item
        v-for="item in arrangementStore.arrangement.items"
        :item="item"
        :is-in-arrangement="true"
        :key="'item-' + item.contentIdentifier"
        v-on:switch-list="removeFromArrangement"
        :draggable="true"
        :is-available="isAvailable(item)"
        :show-arrange-button="arrangementStore.arrangeParagraphEnabled"
        ref="itemComponents"
      />
    </transition-group>
  </vue-draggable>
</template>

<script>
  import { store } from '../../../store'
  import VueDraggable from 'vuedraggable'
  import LearningItem from './LearningItem'

  export default {
    components: {
      LearningItem,
      VueDraggable
    },

    data() {
      return {
        arrangementStore: store.createCurrentArrangementStore()
      }
    },

    computed: {
      dataIsLoaded() {
        return !store.loadingArrangementItems;
      }
    },

    methods: {
      removeFromArrangement (item) {
        store.removeFromArrangement(item)
      },
      onListChange () {
        store.setChangesPending()
      },
      onSpill (evt) {
        const component = this.$refs.itemComponents.find(component => component.$el === evt.item)
        this.removeFromArrangement(component.item)
      },
      isAvailable (item) {
        return this.arrangementStore.isAvailable(item)
      }
    }
  }
</script>
