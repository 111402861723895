var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "pr-3 mb-3 a-modal-content" }, [
        _c(
          "div",
          { staticClass: "confirmation-container" },
          [
            _c("p", { staticClass: "feedback feedback--warning" }, [
              _vm._v(_vm._s(_vm.$t("generic.modal.messages.lose_changes"))),
            ]),
            _vm._v(" "),
            !_vm.hasItems
              ? _c("p", { staticClass: "feedback feedback--warning" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("generic.modal.messages.lose_changes_no_items")
                    )
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.error
              ? _c("warning", { attrs: { message: _vm.error } })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "modal-button-footer",
        [
          _c(
            "custom-button",
            {
              attrs: {
                size: "medium",
                color: "blue",
                disabled: !_vm.hasItems || _vm.isSaving,
                loading: _vm.isSaving,
              },
              on: { click: _vm.save },
            },
            [_vm._v(_vm._s(_vm.$t("generic.buttons.confirm")))]
          ),
          _vm._v(" "),
          _c(
            "custom-button",
            {
              attrs: {
                size: "medium",
                color: "blue",
                variant: "subtle",
                disabled: _vm.isSaving,
              },
              on: { click: _vm.dontSave },
            },
            [_vm._v(_vm._s(_vm.$t("generic.buttons.dont_save")))]
          ),
          _vm._v(" "),
          _c(
            "custom-button",
            {
              attrs: {
                size: "medium",
                color: "blue",
                variant: "ghost",
                disabled: _vm.isSaving,
              },
              on: { click: _vm.cancel },
            },
            [_vm._v(_vm._s(_vm.$t("generic.buttons.cancel")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }