<template>
  <span @click="$emit('click')" class="inline-svg" v-html="svg"></span>
</template>

<script>
  export default {
    props: {
      svg: String,
    }
  }
</script>
