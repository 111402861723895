import '@thiememeulenhoff/albert'
import '@thiememeulenhoff/arrange'

import '@babel/polyfill'
import 'array-flat-polyfill'
import '../../style/style.scss'

import Vue from 'vue'
import VueI18Next from '@panter/vue-i18next'
import VueTranslator from './common/VueTranslator'
import VueInputAutowidth from 'vue-input-autowidth'
import router from './common/router'
import i18n from './common/i18n'
import App from './App.vue'
import { ThasServiceFactory } from './thas/ThasService'
import * as Sentry from "@sentry/vue";
import { GoogleAnalyticsServiceFactory } from './GoogleAnalyticsService';

if (window.sentryConf !== undefined && 'dev' !== window.sentryConf.environment) {
  Sentry.init({
    Vue,
    dsn: "https://6eb6d896e886427aa9286d58c49cfaf2@o1127103.ingest.sentry.io/4504282081132544",
    environment: window.sentryConf.environment,
    trackComponents: true,
  });
}

require('./util/polyfills.js')

Vue.config.ignoredElements = [/^a-/]

Vue.use(VueI18Next)
Vue.use(VueInputAutowidth)

const arrangementsApp = document.getElementById('arrangements-app')

new Vue({
  router,
  i18n: new VueI18Next(i18n),
  el: arrangementsApp,

  components: {
    App
  }
})

if (window.thasConfiguration !== undefined) {
  ThasServiceFactory.createThasService(window.thasConfiguration)
}

document.addEventListener('DOMContentLoaded', function () {
  GoogleAnalyticsServiceFactory.createGoogleAnalyticsService()
})
