<template>
  <div>
    <div
      class="modal-context-menu"
      v-if="triggeredAction === null">
      <a v-for="action in selectedActions"
         :data-test="action.cySelector"
         class="modal-context-menu__item"
         @click.prevent="performAction(action)">{{ action.label }}</a>
    </div>
    <div v-else>
      <component :is="component" :arrangement="arrangement" />
    </div>
  </div>
</template>

<script>
  import ConnectArrangement from '../detail/ConnectArrangement'
  import DeleteArrangement from '../detail/DeleteArrangement'
  import DuplicateArrangement from '../detail/DuplicateArrangement'
  import EditArrangement from '../detail/EditArrangement'
  import { EventBus, MODAL_CLOSE_EVENT, MODAL_OPEN_EVENT, MODAL_SWITCH_EVENT } from '../../../common/EventBus'
  import i18n from '../../../common/i18n'
  import { Prompt } from './ModalContent'

  const availableActions = {
    edit: {
      component: EditArrangement,
      label: i18n.t('generic.context_menu.edit.label'),
      cySelector: 'modal.opion.edit',
      name: 'edit'
    },
    connect: {
      component: ConnectArrangement,
      label: i18n.t('generic.context_menu.connect.label'),
      cySelector: 'modal.opion.connect',
      name: 'connect'
    },
    duplicate: {
      component: DuplicateArrangement,
      label: i18n.t('generic.context_menu.duplicate.label'),
      cySelector: 'modal.opion.duplicate',
      name: 'duplicate'
    },
    delete: {
      component: DeleteArrangement,
      label: i18n.t('generic.context_menu.delete.label'),
      cySelector: 'modal.arrangement.delete',
      name: 'delete'
    }
  }

  export default {
    data () {
      return {
        component: null,
        isOpen: false,
        triggeredAction: null,
        currentTransition: ''
      }
    },

    components: {
      ConnectArrangement,
      DeleteArrangement,
      DuplicateArrangement,
      EditArrangement
    },

    props: {
      actions: Array,
      arrangement: Object
    },

    created () {
      EventBus.$emit(MODAL_SWITCH_EVENT)
    },

    computed: {
      selectedActions () {
        if (this.actions !== undefined) {
          if (this.actions.length === 0 || this.actions[0] === 'all') {
            return availableActions
          }
          return this.actions.map(action => {
            return availableActions[action]
          })
        }
        return availableActions
      }
    },

    methods: {
      performAction (action) {
        this.isOpen = true
        if (action.component) {
          EventBus.$emit(MODAL_OPEN_EVENT, new Prompt[action.name](this.arrangement))
        } else {
          EventBus.$emit(MODAL_CLOSE_EVENT)
          EventBus.$emit(action.name, { arrangement: this.arrangement, action: action })
        }
      },

      returnToActionsList () {
        this.toggleTransition()
        this.triggeredAction = null
      },

      toggleTransition () {
        this.currentTransition = this.currentTransition === '' ? 'left' : 'right'
      }
    }
  }
</script>
