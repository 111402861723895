var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "pill", class: { "pill--large": _vm.large } },
    [
      _vm._v(_vm._s(_vm.label) + " "),
      _c("inline-svg", {
        staticClass: "pill-control",
        attrs: { svg: require("arrange/icon-close.svg") },
        nativeOn: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.$emit("deselect")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }