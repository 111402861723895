var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { ref: "arrangement-action", staticClass: "pr-3 mb-3 a-modal-content" },
        [
          _c("p", {
            staticClass: "feedback feedback--regular",
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("generic.modal.messages.confirm_duplicate", {
                  arrangement: _vm.arrangement.title,
                })
              ),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "modal-button-footer",
        [
          _c(
            "custom-button",
            {
              attrs: {
                size: "medium",
                "data-test": "modal.button.confirm.duplicate",
                color: "blue",
                disabled: _vm.loading,
                loading: _vm.loading,
              },
              on: { click: _vm.duplicateArrangement },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("generic.buttons.duplicate")) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "custom-button",
            {
              attrs: {
                size: "medium",
                color: "blue",
                variant: "ghost",
                disabled: _vm.loading,
              },
              on: { click: _vm.cancel },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("generic.buttons.cancel")) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }