export default class Notification {
  constructor (message, type, icon, label) {
    this.message = message
    this.type = type
    this.icon = icon
    this.label = label
  }

  static warning(message) {
    return new Notification(message, 'warning', 'warning', '');
  }

  static success(message) {
    return new Notification(message, 'success', 'info', '');
  }

  static lock(message) {
    return new Notification(message, 'lock', 'icon-lock icon--grey', '');
  }

  static noReplacement(message) {
    return new Notification(message, 'no-replacement', 'error-solid', '');
  }
}
