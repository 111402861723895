'use strict'
import dotProp from '../util/dot-prop'

export class FilterFactory {
  static create (items, filterConfiguration, metadataService) {
    const filters = []

    for (const filterConfig of filterConfiguration) {
      const filter = Object.assign({}, filterConfig)
      filter.values = filter.values.slice(0)
      filters.push(filter)
    }

    return this.setValuesFromItems(filters, items, metadataService)
  }

  static setValuesFromItems(filters, items, metadataService) {
    for (const filter of filters) {
      filter.values = []
      for (const item of items) {
        const itemValues = dotProp.get(item, filter.field)
        if (itemValues === undefined) {
          continue
        }

        const filterValues = this
          .getFilterValuesFromItemValues(itemValues)
          // Filter entries already present in filter.values
          .filter((value, index, self) => {
            return !filter.values.some(filterValue => filterValue.value === value.value)
          })

        filter.values = filter.values.concat(filterValues)
      }

      // The learningGoals filtervalues only have the paths of the deepest learninggoals.
      // We get the tree of the learningGoals by filtering the metadataService learningGoals
      // by these deepest paths.
      if (metadataService && filter.values.length > 0 && filter.values[0].hasOwnProperty('path')) {
        filter.values = metadataService.getLearningGoals(filter.values.map(value => value.path));
      }

      filter.values
        .sort((a, b) => {
          return a.title.localeCompare(b.title)
        })
    }

    return filters
  }

  static getFilterValuesFromItemValues (itemValues) {
    // itemValues could be a scalar or an array (any item property that's filtered)
    if (!Array.isArray(itemValues)) {
      itemValues = [itemValues]
    }

    itemValues = itemValues.filter(value => value !== null);

    if (itemValues.length > 0 && itemValues[0].hasOwnProperty('vdexListIdentifier')) {
      return itemValues
        .map(itemValue => {
          return { value: itemValue.path, title: itemValue.path, path: itemValue.path }
        })
    }

    return itemValues
      // Filter unique values
      .filter((value, index, self) => self.indexOf(value) === index)
      // Make objects of the values
      .map(itemValue => {
        return { value: itemValue, title: itemValue }
      })
  }
}
