import * as Sentry from "@sentry/vue";

class AssistiveMMLForReadSpeaker {
  // this is a copy of AssistiveMML, but with specific classes so readspeaker doesn't ignore it
  static configure (MathJax) {
    MathJax.Extension.AssistiveMML = {
      version: '2.7.9',

      config: MathJax.Hub.CombineConfig('AssistiveMML', {
        disabled: false,
        styles: {
          '.MJX_Assistive_ReadSpeaker_MathML': {
            position: 'absolute!important',
            top: 0,
            left: 0,
            clip: 'rect(1px, 1px, 1px, 1px)',
            padding: '1px 0 0 0!important',
            border: '0!important',
            height: '1px!important',
            width: '1px!important',
            overflow: 'hidden!important',
            display: 'block!important',
            //
            //  Don't allow the assistive MathML become part of the selection
            //
            '-webkit-touch-callout': 'none',
            '-webkit-user-select': 'none',
            '-khtml-user-select': 'none',
            '-moz-user-select': 'none',
            '-ms-user-select': 'none',
            'user-select': 'none'
          },
          '.MJX_Assistive_ReadSpeaker_MathML.MJX_Assistive_ReadSpeaker_MathML_Block': {
            width: '100%!important'
          }
        }
      }),

      Config: function () {

      },

      //
      //  This sets up a state object that lists the jax and index into the jax,
      //    and a dummy callback that is used to synchronizing with MathJax.
      //    It will be called when the jax are all processed, and that will
      //    let the MathJax queue continue (it will block until then).
      //
      AddAssistiveMathML: function (node) {
        const state = {
          jax: MathJax.Hub.getAllJax(node), i: 0,
          callback: MathJax.Callback({})
        }
        this.HandleMML(state)
        return state.callback
      },

      //
      //  This removes the data-mathml attribute and the assistive MathML from
      //  all the jax.
      //
      RemoveAssistiveMathML: function (node) {
        const jax = MathJax.Hub.getAllJax(node)
        let frame
        for (let i = 0, m = jax.length; i < m; i++) {
          frame = document.getElementById(jax[i].inputID + '-Frame')
          if (frame && frame.getAttribute('data-mathml')) {
            frame.removeAttribute('data-mathml')
            if (frame.lastChild && frame.lastChild.className.match(/MJX_Assistive_ReadSpeaker_MathML/)) {
              frame.removeChild(frame.lastChild)
            }
          }
        }
      },

      //
      //  For each jax in the state, look up the frame.
      //  If the jax doesn't use NativeMML and hasn't already been handled:
      //    Get the MathML for the jax, taking resets into account.
      //    Add a data-mathml attribute to the frame, and
      //    Create a span that is not visible on screen and put the MathML in it,
      //      and add it to the frame.
      //  When all the jax are processed, call the callback.
      //
      HandleMML: function (state) {
        let m = state.jax.length, jax, mml, frame, span
        const MML = MathJax.ElementJax.mml
        MML.copyAttributes.id = 1
        while (state.i < m) {
          jax = state.jax[state.i]
          frame = document.getElementById(jax.inputID + '-Frame')
          if (jax.outputJax !== 'NativeMML' && jax.outputJax !== 'PlainSource' &&
            frame && !frame.getAttribute('data-mathml')) {
            try {
              mml = jax.root.toMathML('').replace(/\n */g, '').replace(/<!--.*?-->/g, '')
            } catch (err) {
              MML.copyAttributes.id = true
              if (!err.restart) throw err // an actual error
              return MathJax.Callback.After(['HandleMML', this, state], err.restart)
            }
            frame.setAttribute('data-mathml', mml)
            span = MathJax.HTML.addElement(frame, 'span', {
              isMathJax: true,
              unselectable: 'on',
              className: 'MJX_Assistive_ReadSpeaker_MathML' + (jax.root.Get('display') === 'block' ? ' MJX_Assistive_ReadSpeaker_MathML_Block' : '')
            })
            try {
              span.innerHTML = mml
            } catch (err) {}
            span.firstChild.classList.remove('rs_skip')
            frame.style.position = 'relative'
            frame.setAttribute('role', 'presentation')
            frame.firstChild.setAttribute('aria-hidden', 'true')
            span.setAttribute('role', 'presentation')
          }
          state.i++
        }
        MML.copyAttributes.id = true
        state.callback()
      }
    }
    MathJax.Hub.Startup.signal.Post('AssistiveMML Ready')

    MathJax.Hub.Register.MessageHook('End Math', function (msg) {
      return window.MathJax.Extension.AssistiveMML.AddAssistiveMathML(msg[1])
    })
  }
}

const _clearMathStyles = mstyleElements => {
  Array.from(mstyleElements).forEach(mstyleElement => {
    Object.keys(mstyleElement.attributes).forEach(key => {
      mstyleElement.attributes.removeNamedItem(mstyleElement.attributes.item(key).name)
    })
  })
}

export default class Equation {
  static typeset (elem) {
    const scriptId = 'mathjax-js-2.7.9'

    // If there is no <math> elements found; do nothing
    if (elem.getElementsByTagName('math').length === 0) {
      return
    }

    _clearMathStyles(elem.querySelectorAll('mstyle'))

    // This fallback exists because vue will cast it to a boolean attribute
    // once the bug has been fixed (https://github.com/vuejs/vue/pull/10088), this open fallback can be removed
    elem.querySelectorAll('[openvue]').forEach((el) => {
      el.setAttribute('open', el.getAttribute('openvue'))
    })

    if (document.getElementById(scriptId) === null || window.MathJax === undefined) {
      // start loading MathJax
      const script = document.createElement('script')
      script.id = scriptId
      script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.9/MathJax.js?delayStartupUntil=configured'
      script.onload = function () {
        window.MathJax.Hub.Config({
          displayAlign: 'left',
          jax: ['input/MathML', 'output/SVG'],
          extensions: ['mml2jax.js', 'toMathML.js'],
          messageStyle: 'none',
          mml2jax: {
            preview: [['div',
              { style: { display: 'inline-block', width: '0.5em', height: '0.5em' } }
            ]]
          },
          SVG: {
            blacker: 10,
            font: 'STIX-Web',
            mtextFontInherit: true,
            scale: 125,
            useGlobalCache: true
          },
          showProcessingMessages: false,
          showMathMenu: false,
          menuSettings: {
            inTabOrder: false
          }
        })
        window.MathJax.Hub.Configured()

        window.MathJax.Hub.Register.MessageHook('Math Processing Error', function (message) {
          console.error('Mathjax processor error information', message)
          Sentry.captureException(new Error('Mathjax processor error information'));
        })
      }

      window.document.getElementsByTagName('head')[0].appendChild(script)
    } else {
      // MathJax was already available, add typesetting of any additional math elements to queue:
      // http://docs.mathjax.org/en/latest/advanced/typeset.html
      window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, elem])
    }
  }
}
