<template>
  <div>
    <div class="pr-3 mb-3 a-modal-content">
      <div class="confirmation-container">
        <p class="feedback feedback--warning">{{ content }}</p>
      </div>
    </div>
    <modal-button-footer>
      <custom-button size="medium" :color="'blue'" @click="confirm">{{ $t(forDelete ? 'generic.buttons.remove' : 'generic.buttons.confirm') }}</custom-button>
      <custom-button size="medium" :color="'blue'" :variant="'ghost'" @click="cancel">{{ $t('generic.buttons.cancel') }}</custom-button>
    </modal-button-footer>
  </div>
</template>

<script>
  import CustomButton from '../../user-interface/Button'
  import ModalButtonFooter from './ModalButtonFooter'

  import { EventBus, MODAL_CLOSE_EVENT } from '../../../common/EventBus'

  export default {
    components: {
      CustomButton,
      ModalButtonFooter
    },

    props: {
      content: String,
      confirmCallback: Function,
      cancelCallback: Function,
      forDelete: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      cancel() {
        this.cancelCallback()
        EventBus.$emit(MODAL_CLOSE_EVENT)
      },
      async confirm() {
        this.confirmCallback()
        EventBus.$emit(MODAL_CLOSE_EVENT)
      }
    }
  }
</script>
