import dotProp from '../util/dot-prop'

class FlattenService {
  flattenMetadata (filterFields, items, showFilterPanel) {
    return items.map(item => {
      filterFields.forEach(filterField => {
        if (dotProp.has(item, filterField.key) && this.flatten(filterField.key, showFilterPanel)) {
          const value = dotProp.get(item, filterField.key)
          if (Array.isArray(value)) {
            dotProp.set(item, filterField.key, this.flattenMetadataCategories(value))
          }
        }
      })
      return item
    })
  }

  flatten(key, showFilterPanel) {
    return !showFilterPanel || key.indexOf('smartMetadata.learningGoals') < 0;
  }

  flattenMetadataCategories (items) {
    return items.map(item => {
      if (typeof item !== 'object') {
        return item
      }

      if (item.items && Array.isArray(item.items) && item.items.length > 0) {
        return this.flattenMetadataCategories(item.items)
      }

      return this.flattenObject(item)
    }).flat()
  }

  flattenObject (item) {
    if (item.hasOwnProperty('category')) {
      return item.category
    }
    if (item.hasOwnProperty('title')) {
      return item.title
    }
    return Object.values(item)[0];
  }
}

export default new FlattenService()
