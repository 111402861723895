var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "arrangement-search-results",
      class: {
        expanded: !_vm.detailStore.collapsed,
        collapsed: _vm.detailStore.collapsed,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "arrangement-search-results__header",
          class: { flexbox: !_vm.detailStore.collapsed },
        },
        [
          _c(
            "div",
            {
              staticClass: "arrangement-search-results__header-inner",
              class: { "flex-align-items--center": !_vm.detailStore.collapsed },
            },
            [
              _c("span", { staticClass: "arrangement-column__header" }, [
                _c("span", { staticStyle: { "text-wrap": "none" } }, [
                  _vm._v(_vm._s(_vm.$t("detail.search.results_header"))),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "arrangement-search-results__label" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.resultCount < _vm.total
                          ? _vm.$t("list.number_of_search_results", {
                              current: _vm.resultCount,
                              total: _vm.total,
                            })
                          : _vm.$t("list.total_search_results", {
                              total: _vm.total,
                            })
                      )
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          !_vm.detailStore.collapsed && !_vm.store.loadingArrangementItems
            ? _c(
                "div",
                {
                  staticClass:
                    "flexbox flex-align-items--center ml-auto arrangement-search-results__search-container",
                },
                [
                  _vm.showFilterPanel
                    ? _c("a-text-input", {
                        attrs: {
                          part: "search",
                          icon: "search",
                          color: "grey",
                          "data-test": "search-input",
                          placeholder: _vm.$t(
                            "generic.input.search.placeholder"
                          ),
                        },
                        on: {
                          search: _vm.handleSearchInput,
                          focus: function ($event) {
                            _vm.detailStore.collapsed = false
                          },
                          input: _vm.handleSearchInput,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { ref: "wc" }),
          _vm._v(" "),
          !_vm.detailStore.collapsed &&
          _vm.detailStore.sortingOptions.length > 1
            ? _c(
                "a-select",
                {
                  staticClass: "mx-4 sorting-select",
                  style: `inline-size: ${_vm.sortSelectorWidth}px`,
                  attrs: {
                    placeholder: _vm.$t(
                      "generic.dropdowns.sorting.placeholder"
                    ),
                    "data-test": "assignment-items-sort-dropdown",
                    disabled: _vm.store.arrangementItems.hasSearchString(),
                  },
                },
                _vm._l(
                  _vm.detailStore.sortingOptions,
                  function (sortingOption, index) {
                    return _c(
                      "a-select-option",
                      {
                        key: "select_option_" + index,
                        attrs: {
                          value: sortingOption.field,
                          "data-test": _vm.createDataTest(sortingOption),
                          selected:
                            _vm.selectedSortingOption.field ===
                            sortingOption.field,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(sortingOption)
                          },
                        },
                      },
                      [_vm._v(_vm._s(sortingOption.title))]
                    )
                  }
                ),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.detailStore.collapsed ? _c("available-learning-items") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }