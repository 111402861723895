'use strict'

import {
  EventBus,
  TOOL_USE_EVENT,
  NAVIGATE_EVENT,
  VIEW_EVENT,
  ACTION_EVENT
} from '../common/EventBus'

import {
  ThasClient,
  ThasViewEvent,
  ThasNavigationEvent,
  ThasToolUseEvent,
  ThasActionEvent
} from '@thiememeulenhoff/analytics-service-client';

export const NAVIGATION_EVENT_ACTION_NAVIGATE_TO = 'navigatedTo';
export const VIEW_EVENT_TAB_ACTIVE = 'active';
export const VIEW_EVENT_TAB_INACTIVE = 'inactive';
export const VIEW_EVENT_INTERVAL_MS = 60000

class ThasService {
  constructor (client, actor, arrangementCode, role, sessionId) {
    this._client = client;
    this._actor = actor;
    this._role = role;
    this._arrangementCode = arrangementCode;
    this._sessionId = sessionId;

    this._sendNavigationEvent(
      this._cleanLocation(document.location.href),
      this._cleanLocation(document.location.referer)
    )

    this._pollCount = 0;
    setInterval(() => {
      this._sendViewEvent(
        this._cleanLocation(document.location.href),
        this._pollCount
      )
      this._pollCount++
    }, VIEW_EVENT_INTERVAL_MS)

    EventBus.$on(TOOL_USE_EVENT, (href, toolType) => {
      this._sendToolUseEvent(href, toolType)
    })

    EventBus.$on(NAVIGATE_EVENT, (href) => {
      this._sendNavigationEvent(href, this._cleanLocation(document.location.href))
    })

    EventBus.$on(VIEW_EVENT, (href, iteration) => {
      this._sendViewEvent(href, iteration)
    })
  }

  _cleanLocation(url) {
    if (undefined === url || null === url) {
      return null
    }

    const parts = url.split('#')
    if (parts.length > 1 && parts[1].length > 50) {
      return parts[0]
    }

    return url;
  }

  _absoluteUri(href) {
    try {
      return new URL(href, document.baseURI).href
    } catch(err) {}
    return href
  }

  _sendNavigationEvent(href, referer) {
    this._sendEvent(new ThasNavigationEvent(
        NAVIGATION_EVENT_ACTION_NAVIGATE_TO,
      this._absoluteUri(href),
      referer,
    ))
  }

  _sendToolUseEvent(href, toolType) {
    const referer = this._cleanLocation(document.location.href)
    const target = toolType || href

    this._sendEvent(new ThasToolUseEvent(
      target,
      referer,
    ))
  }

  _sendViewEvent(href, iteration) {
    this._sendEvent(new ThasViewEvent(
      this._absoluteUri(href),
      iteration,
      document.hasFocus() ? VIEW_EVENT_TAB_ACTIVE : VIEW_EVENT_TAB_INACTIVE,
    ))
  }

  _sendEvent (event) {
    try {
      this._client.send(event);
    } catch (e) {
      console.error('Thas error', e);
    }
  }
}

export class ThasServiceFactory {
  static createThasService(configuration) {
    const thasClient = new ThasClient({
      token: configuration.token,
      baseUrl: configuration.baseUrl,
    });

    thasClient.setActor({
      actor: configuration.actor,
      role: configuration.role,
    });
    return new ThasService(thasClient, configuration.actor, configuration.arrangementCode, configuration.role, configuration.sessionId)
  }
}
