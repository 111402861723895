var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filter-accordion_item p-1" }, [
    _c(
      "h4",
      {
        staticClass: "filter-accordion_header p-1",
        attrs: { "data-test": this.getTestSelector(_vm.title) },
        on: {
          click: function ($event) {
            return _vm.onClick()
          },
        },
      },
      [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
        _c("inline-svg", {
          staticClass: "filter-accordion_icon ml-auto mr-3",
          class: { rotated: !_vm.collapsed },
          attrs: { svg: require("arrange/icon-caret.svg") },
        }),
      ],
      1
    ),
    _vm._v(" "),
    !_vm.collapsed
      ? _c("div", { staticClass: "filter-accordion_options-container" }, [
          _c(
            "ul",
            { staticClass: "filter-options p-1 pr-4" },
            [
              _vm.options.length === 0
                ? _c("li", [
                    _c("span", { staticClass: "filter-option-text" }, [
                      _vm._v(
                        _vm._s(_vm.$t("detail.search.no_filters_available"))
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.options, function (option) {
                return _c(
                  "li",
                  [
                    _c("filter-item-value", {
                      attrs: { category: _vm.category, value: option },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }