<template>
  <div class="arrangement-search-controls pt-4">
    <h3 class="arrangement-column__header pl-0">{{ showFilterPanel ? $t('detail.search.filter_header') : $t('detail.search.search_filter_header') }}</h3>

    <div class="arrangement-search-wrapper mb-2 mt-2 pr-3" v-if="!showFilterPanel">
      <a-text-input
          part="search"
          icon="search"
          color="grey"
          data-test="search-input"
          :placeholder="$t('generic.input.search.placeholder')"
          @search="handleInput"
          @focus="detailStore.collapsed = false"
          @input="handleInput"
      ></a-text-input>
    </div>

    <selected-filters v-if="!showFilterPanel"/>
    <filter-accordion/>
  </div>
</template>

<script>
  import FilterAccordion from './Accordion'
  import SelectedFilters from './SelectedFilters'
  import { store, detailStore } from '../../../../store'

  export default {
    components: {
      FilterAccordion,
      SelectedFilters
    },
    data () {
      return {
        store,
        detailStore
      }
    },
    methods: {
      handleInput (event) {
        store.arrangementItems.search(event.target.value)
      },
      showFilterPanel() {
        return store.showFilterPanel();
      },
    }
  }
</script>
