var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "arrangement-search-controls pt-4" },
    [
      _c("h3", { staticClass: "arrangement-column__header pl-0" }, [
        _vm._v(
          _vm._s(
            _vm.showFilterPanel
              ? _vm.$t("detail.search.filter_header")
              : _vm.$t("detail.search.search_filter_header")
          )
        ),
      ]),
      _vm._v(" "),
      !_vm.showFilterPanel
        ? _c(
            "div",
            { staticClass: "arrangement-search-wrapper mb-2 mt-2 pr-3" },
            [
              _c("a-text-input", {
                attrs: {
                  part: "search",
                  icon: "search",
                  color: "grey",
                  "data-test": "search-input",
                  placeholder: _vm.$t("generic.input.search.placeholder"),
                },
                on: {
                  search: _vm.handleInput,
                  focus: function ($event) {
                    _vm.detailStore.collapsed = false
                  },
                  input: _vm.handleInput,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.showFilterPanel ? _c("selected-filters") : _vm._e(),
      _vm._v(" "),
      _c("filter-accordion"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }