<template>
  <div class="dropdown" v-bind:class="classes">
    <div ref="trigger" class="dropdown__trigger" @click="toggle">
      <template v-if="$slots['title']">
        <slot name="title"></slot>
      </template>
      <span v-else class="dropdown__trigger-label mr-1" v-html="title"></span>
      <a-icon class="icon-tm-arrow-down dropdown__trigger-arrow" name="chevron-down"/>
    </div>
    <div ref="dropdownMenu" class="dropdown__menu" role="menu" v-if="open" v-on-clickaway="close" body-scroll-lock-ignore>
      <div ref="dropdownContent" class="dropdown__content">
        <template v-if="$slots['content']">
          <slot name="content"></slot>
        </template>
        <template v-else v-for="option in options">
          <a v-if="option.divider !== true" class="dropdown__item"
             v-bind:class="{ 'dropdown__item--selected': isSelected(option) }"
             v-html="option[labelAttribute]"
             @click="selectOption(option)"
             :data-test="dropdownItemDataTest(option)"
          ></a>
          <div v-if="option.divider === true" class="dropdown__divider">
            <span class="dropdown__divider--label">
              <i v-if="option.icon" :class="option.icon" class="mr-1"></i>{{ option[labelAttribute] }}
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { DropdownPopper } from './dropdown/DropdownPopper'
  import { mixin as clickaway } from 'vue-clickaway'
  import Equation from './Equation'

  export default {
    mixins: [clickaway],
    dropdownPopper: null,
    data: function () {
      return {
        open: false,
        selectedOption: this.predefinedOption || null,
      }
    },
    props: {
      predefinedOption: Object,
      options: Array,
      selectTitle: String,
      triggerOnly: Boolean,
      triggerSelectedOption: {
        type: Boolean,
        default: true
      },
      loading: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      labelAttribute: {
        type: String,
        default: 'name'
      }
    },
    created () {
      if (this.options instanceof Array) {
        this.options.forEach((option) => {
          if (true === option.selected) {
            this.selectedOption = option
          }
        })
      }

      this.$options.dropdownPopper = new DropdownPopper()
    },
    async updated () {
      await this.$nextTick()
      Equation.typeset(this.$el)
    },
    beforeDestroy () {
      this.$options.dropdownPopper.destroy()
    },
    computed: {
      title () {
        if (null === this.selectedOption) {
          return this.selectTitle
        }
        return this.selectedOption[this.labelAttribute]
      },
      classes () {
        return {
          'dropdown--open': this.open,
          'dropdown--loading': this.loading,
          'dropdown--disabled': this.disabled
        }
      }
    },
    methods: {
      isSelected (option) {
        return option === this.selectedOption
      },
      dropdownItemDataTest (option) {
        return 'dropdown-item-' + option[this.labelAttribute].toLowerCase()
      },
      close () {
        this.open = false
        this.$options.dropdownPopper.destroy()
      },
      toggle () {
        this.open = !this.open
        if (this.open) {
          this.createPopper()
          return
        }
        this.close()
      },
      selectOption (option, event) {
        if (!this.triggerSelectedOption && this.isSelected(option)) {
          this.toggle()
          return
        }

        if (!this.triggerOnly) {
          this.selectedOption = option
        }

        if (option.url !== undefined) {
          window.location.href = option.url
        }

        this.$emit('option-selected', option)
        this.toggle()
      },
      async createPopper () {
        await this.$nextTick()
        this.$options.dropdownPopper.createPopper(
          this.$refs.dropdownContent,
          this.$refs.trigger,
          this.$refs.dropdownMenu
        )

        this.$emit('open')
      }
    }
  }
</script>
