var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", {
    staticClass: "inline-svg",
    domProps: { innerHTML: _vm._s(_vm.svg) },
    on: {
      click: function ($event) {
        return _vm.$emit("click")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }