var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flexbox flexbox--column flex" },
    [
      _c(
        "custom-header",
        { attrs: { "section-title": _vm.$route.meta.title } },
        [
          _c(
            "div",
            {
              staticClass: "section-header__button-container",
              attrs: { slot: "buttons" },
              slot: "buttons",
            },
            [
              this.store.hasAdminLicense
                ? _c(
                    "a-button",
                    {
                      attrs: {
                        variant: "subtle",
                        color: "blue",
                        size: "medium",
                        "data-test": "button.switch-role",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.switchRole()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            this.store.inAdminMode
                              ? this.$i18n.t(
                                  "generic.buttons.switch.to_instructor"
                                )
                              : this.$i18n.t("generic.buttons.switch.to_admin")
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("LoadingMessage", { attrs: { loading: _vm.isLoading } }),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "methods-container px-3" },
            [
              !this.store.inAdminMode
                ? _c("warning", {
                    attrs: { message: "Error: You are not in admin mode." },
                  })
                : _vm._e(),
              _vm._v(" "),
              this.store.inAdminMode
                ? _c("span", [
                    _c("h1", { staticClass: "h3" }, [
                      _vm._v(
                        _vm._s(this.$i18n.t("generic.arrangeable_methods"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "table",
                      {
                        staticClass:
                          "table table--full-width table--bordered table--condensed",
                      },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(this.store.methods, function (method) {
                            return _c("tr", [
                              _c("td", [
                                _c(
                                  "a",
                                  { attrs: { href: _vm.getUrl(method) } },
                                  [_vm._v(_vm._s(method.name))]
                                ),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [_c("tr", [_c("th", [_vm._v("Methode")])])])
  },
]
render._withStripped = true

export { render, staticRenderFns }