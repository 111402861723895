<template>
    <div class="selected-filters-container pl-2 pr-4" v-if="!!selectedFilters.length">
        <a class="selected-filters-clear-trigger flexbox mb-2" @click.prevent="resetAllFilters">{{
            $t('list.clear_filters') }}</a>
        <div class="selected-filters__inner">
            <pill
                    v-for="filter in selectedFilters"
                    class="selected-filters__item mb-1"
                    :id="filter.id"
                    :label="filter.title"
                    :key="filter.id"
                    :value="filter.value"
                    v-on:deselect="clearFilterValue(filter)"
            />
        </div>
    </div>
</template>

<script>
  import { store } from '../../../../store'
  import Pill from '../../../user-interface/Pill'

  export default {
    data () {
      return {
        store: store,
        useNewFilters: store.showFilterPanel()
      }
    },

    components: {
      Pill
    },

    computed: {
      selectedFilters () {
        const filters = []

        const activeFilters = store.arrangementItems.activeFilters
        const keys = Object.keys(activeFilters)

        for (const key of keys) {
          filters.concat(activeFilters[key].map(filter => {
            filters.push(this.getActiveFilterObject(filter, key));
          }))
        }

        return filters
      }
    },

    methods: {
      getActiveFilterObject(filter, field) {
        if (this.useNewFilters) {
          return {
            title: filter.title,
            field: field,
            id: field + '_' + filter.value,
            value: filter.value
          };
        }

        return {
          title: filter.title,
          field: field,
          id: field + '_' + filter.value,
          value: filter.title
        };
      },

      clearFilterValue (filter) {
        store.arrangementItems.removeFilterValue(filter.field, filter.value)
      },

      resetAllFilters () {
        store.arrangementItems.clearAllFilters()
      }
    }
  }
</script>
