<template>
  <div :class="`v3-modal-footer`">
    <div :class="`v3-modal-footer__button-container`">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {}
</script>
