<template>
  <div class="flexbox flexbox--column flex">
    <LoadingMessage :for-page="true" :loading="isLoading"/>

    <template v-if="!isLoading">
      <detail-header
        :arrangement="arrangement"
        :error="error"
        :is-saving="isSaving"
        @save-arrangement="checkSaveArrangement"
      />

      <div class="arrangements-container px-3">
        <div class="arrangements-list__content flexbox flex-align-items--start justify-content-center">
          <div class="arrangement-detail">

            <div class="arrangement__columns-container flexbox">
              <!-- Search/filter arrangement column -->
              <div class="arrangement__column arrangement__column--search-filter">
                <filter-controls/>
                <filter-results/>
              </div>

              <!-- Arrange content column   -->
              <div class="arrangement__column arrangement__column--arrange py-3 pl-3">
                <h3 class="arrangement-column__header mb-0">{{ arrangement.title }}</h3>

                <arrangement-items v-if="arrangement.items"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ROOT-ELEMENT FOR MODAL -->
      <modal-root/>

    </template>
  </div>
</template>

<script>
import InlineSvg from '../../components/InlineSvg'
import { detailStore, store } from '../../store'
import DetailHeader from './detail/DetailHeader'
import Dropdown from '../../components/Dropdown'
import ArrangementItems from './detail/ArrangementItems'
import ModalRoot from './modal/ModalRoot'
import LoadingMessage from './detail/LoadingMessage'
import FilterControls from './detail/filters/FilterControls'
import FilterResults from './detail/filters/Results'
import Warning from '../../components/notification/Warning'
import {ACTION_EVENT, ARRANGEMENT_GROUPS_UPDATED, EventBus, TOOL_USE_EVENT} from '../../common/EventBus'
import { confirm } from './modal/Dialogs'
import {captureException} from "@sentry/vue";

export default {
  props: {
    code: String
  },

  components: {
    Warning,
    LoadingMessage,
    InlineSvg,
    ArrangementItems,
    Dropdown,
    ModalRoot,
    DetailHeader,
    FilterControls,
    FilterResults
  },

  data () {
    // Start with isLoading is true because we start loading when mounted
    // this will prevent a double call getting the available-learning-items
    return {
      isLoading: true,
      error: null,
      isSaving: false,
      store,
      detailStore
    }
  },

  watch: {
    'store.arrangementItems.searchString' (value) {
      if (value !== '') {
        this.detailStore.collapsed = false
      }
    }
  },

  created () {
    detailStore.collapsed = false
  },

  mounted () {
    this.getData()
    this.setupListeners()
  },

  destroyed () {
    document.body.classList.toggle('arrangement-page', false);
  },

  computed: {
    arrangement () {
      return store.currentArrangement
    },
  },

  methods: {
    async getData () {
      this.isLoading = true
      await store.loadArrangement(this.code)
      this.isLoading = false
      document.body.classList.toggle('arrangement-page', true);
    },

    setupListeners: function () {
      EventBus.$on(ARRANGEMENT_GROUPS_UPDATED, (arrangementCode, groups) => {
        this.arrangement.audienceGroups = groups
      })
    },

    async checkSaveArrangement () {
      EventBus.$emit(TOOL_USE_EVENT, document.url, 'Save Arrangement')

      if (store.showSaveWarning(this.arrangement)) {
        confirm(
          this.$t('generic.modal.titles.not_your_arrangement'),
          this.$t('generic.modal.messages.confirm_save_not_your_arrangement'),
          () => this.saveArrangement()
        )
      } else {
        await this.saveArrangement()
      }
    },

    async saveArrangement () {
      this.error = null
      this.isSaving = true
      try {
        await store.saveArrangementContent(this.arrangement)
      } catch (exception) {
        captureException(new Error(exception.message))
        this.error = exception.message
      } finally {
        this.isSaving = false
      }
    }
  }
}
</script>
