var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: `v3-modal-footer` }, [
    _c(
      "div",
      { class: `v3-modal-footer__button-container` },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }