<template>
  <div>
    <div class="pr-3 mb-3 a-modal-content" ref="arrangement-action">
      <p class="feedback feedback--regular"
         v-html="$t('generic.modal.messages.confirm_duplicate', { arrangement: arrangement.title })"></p>
    </div>
    <modal-button-footer>
      <custom-button
        size="medium"
        data-test="modal.button.confirm.duplicate"
        :color="'blue'"
        :disabled="loading"
        :loading="loading"
        @click="duplicateArrangement">
        {{ $t('generic.buttons.duplicate') }}
      </custom-button>
      <custom-button size="medium" :color="'blue'" :variant="'ghost'" :disabled="loading" @click="cancel">
        {{ $t('generic.buttons.cancel') }}
      </custom-button>
    </modal-button-footer>
  </div>
</template>

<script>
  import {
    EventBus,
    MODAL_CLOSE_EVENT,
  } from '../../../common/EventBus'
  import CustomButton from '../../user-interface/Button'
  import ModalButtonFooter from '../modal/ModalButtonFooter'
  import { store } from '../../../store'
  import router from '../../../common/router'
  import { captureException } from "@sentry/vue";

  export default {
    components: {
      CustomButton,
      ModalButtonFooter
    },

    props: {
      arrangement: Object
    },

    data () {
      return {
        loading: false
      }
    },

    methods: {
      cancel () {
        EventBus.$emit(MODAL_CLOSE_EVENT)
      },
      async duplicateArrangement () {
        this.loading = true
        try {
          const arrangementCode = await store.createArrangementFromArrangement(this.arrangement.code, {})
          router.push({ name: 'arrangement-detail', params: { code: arrangementCode } }).catch(() => {})
        } catch (exception) {
          captureException(new Error(exception.message))
          this.error = exception.message
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
