var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    {
      staticClass:
        "learning-item learning-item--empty learning-item--no-interaction learning-item--no-replacement",
    },
    [
      _c("warning", {
        attrs: {
          message: _vm.$t("generic.modal.messages.no_replacement_item"),
          type: "no-replacement",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }