import { routeGet } from '../util/request'

class MetadataService {
  constructor() {
    this.learningGoals = null;
    this.currentMethod = null;
  }

  async loadLearningGoals (methodSlug) {
    if (null === this.learningGoals || this.currentMethod !== methodSlug) {
      const response = await routeGet(
        'arrange_metadata_learninggoals',
        { methodSlug: methodSlug }
      )

      this.learningGoals = response.data;
      this.currentMethod = methodSlug;
    }
  }

  getLearningGoals (paths) {
    return this.learningGoalsForPaths(this.learningGoals, '', paths)
  }

  learningGoalsForPaths(goals, basePath, paths) {
    return goals.map(goal => {
      const goalPath = basePath === '' ? goal.identifier : basePath + '/' + goal.identifier;

      const item = {};
      item.values = this.learningGoalsForPaths(goal.children, goalPath, paths);
      const hasChildren = item.values.some(child => child.include);

      item.include = paths.indexOf(goalPath) >= 0 || hasChildren;
      item.value = goal.identifier;
      item.title = goal.title;

      return item;
    }).filter(item => item.include);
  }
}

export default MetadataService
