<template>
  <div class="filter-accordion_item p-1">
    <h4 :data-test="this.getTestSelector(title)" class="filter-accordion_header p-1" @click="onClick()">
      {{ title }}
      <inline-svg
        class="filter-accordion_icon ml-auto mr-3"
        :class="{ 'rotated' : !collapsed }"
        :svg="require('arrange/icon-caret.svg')"
      /></h4>
    <div class="filter-accordion_options-container" v-if="!collapsed">
      <ul class="filter-options p-1 pr-4">
        <li v-if="options.length === 0">
          <span class="filter-option-text">{{ $t('detail.search.no_filters_available') }}</span>
        </li>
        <li v-for="option in options">
          <filter-item-value :category="category" :value="option" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import InlineSvg from '../../../../components/InlineSvg'
  import FilterItemValue from './FilterItemValue'

  export default {
    data () {
      return {
        collapsed: true
      }
    },

    props: {
      category: String,
      options: Array,
      title: String
    },

    components: {
      InlineSvg,
      FilterItemValue
    },
    methods: {
      onClick() {
        return this.collapsed = !this.collapsed
      },
      getTestSelector(title) {
        return `filter-accordion-title-${title.toLowerCase()}`
      }
    }
  }
</script>
