import Vue from 'vue'
import Router from 'vue-router'
import i18n from '../common/i18n'
import ArrangementsIndex from '../components/sections/ArrangementsIndex'
import MethodsIndex from '../components/sections/MethodsIndex.vue'
import ArrangementDetail from '../components/sections/ArrangementDetail'
import {EventBus, NAVIGATE_EVENT} from "./EventBus";

Vue.use(Router)

class RouteMetadata {
  constructor (hideNavigation, title) {
    this.hideNavigation = hideNavigation
    this.title = title
  }

  static WithNavigation (title) {
    return new RouteMetadata(false, title)
  }

  static WithoutNavigation (title) {
    return new RouteMetadata(true, title)
  }
}

const router = new Router({
  routes: [
    {
      name: 'arrangements-index',
      path: '/',
      alias: '/index',
      component: ArrangementsIndex,
      meta: RouteMetadata.WithoutNavigation(i18n.t('generic.my_arrangements'))
    },

    {
      name: 'methods-list',
      path: '/methods-list',
      component: MethodsIndex,
      meta: RouteMetadata.WithNavigation(i18n.t('generic.methods'))
    },

    {
      name: 'new-arrangement-start',
      path: '/new-arrangement-start',
      component: ArrangementsIndex,
      meta: RouteMetadata.WithNavigation(i18n.t('generic.new_arrangement'))
    },

    {
      name: 'new-arrangement-from-template',
      path: '/new-arrangement/from-template/:code',
      component: ArrangementDetail,
      props: true,
      meta: RouteMetadata.WithNavigation(i18n.t('generic.new_arrangement_title'))
    },

    {
      name: 'new-arrangement',
      path: '/new-arrangement/blank',
      component: ArrangementDetail,
      props: true,
      meta: RouteMetadata.WithNavigation(i18n.t('generic.new_arrangement_title'))
    },

    {
      name: 'arrangement-detail',
      path: '/arrangement-detail/:code',
      component: ArrangementDetail,
      props: true
    }
  ]
})

router.beforeEach((to, from, next) => {
  EventBus.$emit( NAVIGATE_EVENT, to.fullPath, from.fullPath)
  next();
})

export default router;
