'use strict'
import dotProp from '../util/dot-prop'
import { locales } from '../common/locales'

export class MetadataTranslateService {
  static process(data) {
    for (const key in locales.nl.metadataValues) {
      for (const itemKey in data) {
        const val = dotProp.get(data[itemKey], key);
        const mval = dotProp.get(data[itemKey], 'mergedMetadata.' + key);
        if (val || mval) {
          const translation = locales.nl.metadataValues[key][val];
          if (val && translation) {
            dotProp.set(data[itemKey], key, translation);
          }
          if (mval && translation) {
            dotProp.set(data[itemKey], 'mergedMetadata.' + key, translation);
          }
        }
      }
    }

    return data;
  }
}
