var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { ref: "arrangement-action", staticClass: "pr-3 mb-3 a-modal-content" },
        [
          _vm.loading ? _c("modal-loading") : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "new-arrangement-container" }, [
            _c("div", { staticClass: "new-arrangement-header" }, [
              _c("span", { staticClass: "new-arrangement-header__label" }, [
                _vm._v(_vm._s(_vm.arrangement.title)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "new-arrangement-form-container mt-2" },
              [
                _c(
                  "a-text-input",
                  {
                    attrs: {
                      "data-test": "edit-arrangement-form.title",
                      type: "text",
                      placeholder: _vm.$t("placeholders.new_name_label"),
                      value: _vm.newArrangement.title,
                      disabled: _vm.loading,
                    },
                    on: { input: _vm.setTitle },
                  },
                  [
                    _vm._v("\n          Titel\n          "),
                    _c("span", { attrs: { slot: "error" }, slot: "error" }, [
                      _vm._v(
                        _vm._s(_vm.$t("generic.modal.messages.empty_title"))
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a-text-area",
                  {
                    attrs: {
                      "data-test": "edit-arrangement-form.description",
                      rows: "5",
                      maxlength: "255",
                      placeholder: _vm.$t("placeholders.new_name_description"),
                      value: _vm.newArrangement.description,
                      disabled: _vm.loading,
                    },
                    on: { input: _vm.setDescription },
                  },
                  [_vm._v("\n          Omschrijving\n        ")]
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("warning", { attrs: { message: _vm.error } })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal-button-footer",
        [
          _c(
            "custom-button",
            {
              attrs: {
                size: "medium",
                color: "blue",
                disabled: _vm.loading || !_vm.isTitleValid,
                loading: _vm.loading,
              },
              on: { click: _vm.checkSaveArrangement },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("generic.buttons.save")) + "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "custom-button",
            {
              attrs: {
                size: "medium",
                color: "blue",
                variant: "ghost",
                disabled: _vm.loading,
              },
              on: { click: _vm.cancel },
            },
            [_vm._v(_vm._s(_vm.$t("generic.buttons.cancel")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }