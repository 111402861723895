'use strict'

import i18next from 'i18next'
import { locales } from './locales'

i18next.init({
  lng: 'nl',
  whitelist: ['nl'],
  resources: {
    nl: { translation: locales.nl }
  }
})

export default i18next
