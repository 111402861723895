import Vue from 'vue'

export const MODAL_OPEN_EVENT = 'open'
export const MODAL_CLOSE_EVENT = 'close'
export const MODAL_DISABLE_EVENT = 'disable-modal'
export const MODAL_ACTIVATE_EVENT = 'activate-modal'
export const MODAL_SWITCH_EVENT = 'switch'
export const MODAL_REVERSE_SWITCH_EVENT = 'reverse-switch'
export const MODAL_RETURN_TO_ACTIONS_LIST_EVENT = 'return-to-actions-list'

export const MODAL_LOADING_EVENT = 'loading'
export const MODAL_LOADING_COMPLETE_EVENT = 'loading-complete'

export const ARRANGEMENT_GROUPS_UPDATED = 'arrangement-groups-updated'

export const TOOL_USE_EVENT = 'tool-use'
export const NAVIGATE_EVENT = 'page-navigate'
export const VIEW_EVENT = 'page-view'

export const ACTION_EVENT = 'action'

export const ASSIGNMENTCARD_OPEN = 'assignment-card-open'

export const EventBus = new Vue()
