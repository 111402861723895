var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          ref: "arrangement-action",
          staticClass: "pr-3 mb-3 a-modal-content arrangement-connect-groups",
        },
        [
          _vm.state.showLoadingScreen()
            ? _c("loading-message", { attrs: { loading: true } })
            : _vm.state.showEditScreen()
            ? [
                _c("p", {
                  staticClass: "feedback feedback--regular",
                  domProps: { innerHTML: _vm._s(_vm.headingText) },
                }),
                _vm._v(" "),
                _c(
                  "a-select",
                  {
                    key: "group-selector-" + _vm.groupSelectorKey,
                    staticClass: "mb-2",
                    attrs: {
                      placeholder: _vm.$t(
                        "generic.modal.messages.select_group"
                      ),
                      "data-test": "dropdown-select-group",
                      disabled: _vm.state.connectButtonDisabled(),
                    },
                    on: { change: _vm.addGroupNew },
                  },
                  _vm._l(_vm.availableGroups, function (group, index) {
                    return _c(
                      "a-select-option",
                      {
                        key: "select_option_" + index,
                        attrs: {
                          value: group.id,
                          "data-test": "dropdown-select-group-option-" + index,
                        },
                      },
                      [_vm._v(_vm._s(group.name) + "\n        ")]
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                _vm.selectedGroupIds.length > 0
                  ? _c("p", { staticClass: "text-bold" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("connect.groups_selected", {
                            selected: _vm.selectedGroupIds.length,
                            total: _vm.store.groups.lengthCurrent,
                          })
                        )
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedGroupIds.length === 0
                  ? _c("p", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.$t("connect.no_groups_selected"))),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "transition-group",
                  {
                    staticClass: "arrangement-connect-groups__pills-container",
                    attrs: { tag: "div", name: "component-fade" },
                  },
                  _vm._l(_vm.selectedGroupIds, function (selectedGroupId) {
                    return _c(
                      "a-border-block",
                      {
                        key: selectedGroupId,
                        attrs: {
                          type: "filter",
                          size: "small",
                          name: _vm.getGroupLabel(selectedGroupId),
                          disabled: _vm.state.connectButtonDisabled(),
                          closeable: "",
                        },
                        on: {
                          close: function ($event) {
                            return _vm.removeGroup(selectedGroupId)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.getGroupLabel(selectedGroupId)))]
                    )
                  }),
                  1
                ),
              ]
            : _vm.state.showConfirmRemoveScreen()
            ? [
                _c("a-alert", { attrs: { type: "warning" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("connect.confirm_remove_connected_group"))
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "modal-button-footer",
        [
          _vm.state.showConfirmRemoveScreen()
            ? [
                _c(
                  "a-button",
                  {
                    attrs: { "data-test": "modal.button.remove", color: "red" },
                    on: {
                      click: function ($event) {
                        return _vm.state.confirmRemove()
                      },
                    },
                  },
                  [
                    _c("a-icon", { attrs: { name: "delete" } }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("generic.buttons.remove")) +
                        "\n      "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "a-button",
                  {
                    attrs: {
                      "data-test": "modal.button.cancel",
                      color: "blue",
                      variant: "ghost",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.state.cancelRemove()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("generic.buttons.cancel")) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            : [
                _c(
                  "a-button",
                  {
                    attrs: {
                      "data-test": "modal.button.connect",
                      disabled: _vm.state.connectButtonDisabled(),
                      color: "blue",
                    },
                    on: { click: _vm.checkConnect },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("generic.buttons.save")) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a-button",
                  {
                    attrs: {
                      "data-test": "modal.button.cancel",
                      disabled: _vm.state.connectButtonDisabled(),
                      color: "blue",
                      variant: "ghost",
                    },
                    on: { click: _vm.cancel },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("generic.buttons.cancel")) +
                        "\n      "
                    ),
                  ]
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }