import {EventBus, MODAL_OPEN_EVENT} from './common/EventBus'
import {Prompt} from './components/sections/modal/ModalContent'

export default class ArrangementStore {
  constructor (store, arrangement, arrangeParagraphEnabled) {
    this.store = store
    this.arrangement = arrangement
    this.arrangeParagraphEnabled = arrangeParagraphEnabled
    this.loaded = false
    this.availableItems = []
    this.contentIdentifiers = []
    this.invalidItems = []
  }

  setAvailableItems(items) {
    this.loaded = true
    this.availableItems = items
    this.contentIdentifiers = this.availableItems.map(item => item.contentIdentifier)

    this.invalidItems = this.arrangement.items
      .filter(item => !this.isAvailable(item))
      .map(item => {
        return {
          item: item,
          replacement: this.getReplacementItem(item)
        }
      })

    if (this.invalidItems.length > 0) {
      EventBus.$emit(MODAL_OPEN_EVENT, Prompt.fixArrangement(this.arrangement))
    }
  }

  async fixArrangement() {
    const replacements = {}
    for (const item of this.invalidItems) {
      replacements[item.item.contentIdentifier] = item.replacement
    }

    this.arrangement.items = this.arrangement.items
      .map(item => replacements.hasOwnProperty(item.contentIdentifier) ? replacements[item.contentIdentifier] : item)
      .filter(item => null !== item)

    await this.store.saveArrangementContent(this.arrangement)

    this.invalidItems = []
  }

  getInvalidItems() {
    return this.invalidItems
  }

  getReplacementItem (item) {
    for (const replacement of this.availableItems) {
      if (this._isReplacement(item, replacement)) {
        return replacement
      }
    }
    return null
  }

  _isReplacement(item, replacement) {
    return this._thedId(item) === this._thedId(replacement)
  }

  _thedId(item) {
    return item.thedContentIdentifier.substr(0, item.thedContentIdentifier.length - 4)
  }

  isAvailable(item) {
    return !this.loaded || this.contentIdentifiers.indexOf(item.contentIdentifier) >= 0
  }

  canSave() {
    return 0 === this.invalidItems.length
  }
}
