<template>
  <div class="flexbox flexbox--column flex-align-items--center flex-justify-content--center flex" v-if="loading">
    <a-loader :variant="forPage ? 'page' : 'default'">{{ $t('generic.loading') }}</a-loader>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    forPage: {
      type: Boolean,
      default: false
    }
  }
}
</script>
