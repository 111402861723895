import request, { routePost } from '../util/request'
import Routing from '../util/routing'
import { ArrangementItems } from '../store.arrangement-items'
import flattenService from '../services/FlattenService'
import MetadataService from '../services/MetadataService'
import { MetadataTranslateService } from '../services/MetadataTranslateService'

class ArrangementService {
  constructor (methodSlug, showFilterPanel, forPredefinedArrangements) {
    this.methodSlug = methodSlug
    this.metadataService = showFilterPanel ? new MetadataService() : null
    this.forPredefinedArrangements = forPredefinedArrangements
  }

  async createArrangement (arrangementData, inAdminMode) {
    const route = inAdminMode ? 'arrange_arrangement_create_predefined' : 'arrange_arrangement_create';
    const { data } = await routePost(
      inAdminMode ? 'arrange_arrangement_create_predefined' : 'arrange_arrangement_create',
      { methodSlug: this.methodSlug },
      {
        title: arrangementData.title,
        description: arrangementData.description,
      }
    )
    return data
  }

  async updateArrangement (arrangementCode, data) {
    await routePost('arrange_arrangement_update', {
      methodSlug: this.methodSlug,
      arrangementCode: arrangementCode
    }, data)
  }

  async saveArrangementContent (arrangement) {
    const payload = arrangement.items.map((item) => {
      return {
        contentIdentifier: item.contentIdentifier,
        children: item.children ? item.children.map(child => child.contentIdentifier) : []
      }
    })

    await routePost('arrange_arrangement_save_content', {
      methodSlug: this.methodSlug,
      arrangementCode: arrangement.code
    }, payload)
  }

  async loadMyArrangements (inAdminMode) {
    return await this.loadArrangements(inAdminMode ? 'arrange_my_predefined_arrangements' : 'arrange_my_arrangements')
  }

  async loadTemplateArrangements () {
    return await this.loadArrangements('arrange_template_arrangements')
  }

  async loadArrangements (endpoint) {
    const url = Routing.generate(endpoint, { methodSlug: this.methodSlug })
    const response = await request(url)
    return response.data
  }

  async loadArrangement (arrangementCode) {
    const params = {
      methodSlug: this.methodSlug,
      arrangementCode: arrangementCode
    }
    const response = await request(Routing.generate('arrange_arrangement_detail', params))
    return response.data
  }

  async getContent (arrangementCode) {
    const params = {
      methodSlug: this.methodSlug,
      arrangementCode: arrangementCode
    }
    const { data } = await request(Routing.generate('arrange_arrangement_get_content', params))
    return data
  }

  async loadLearningItems (filterFields, itemMetadataKeys, updateFiltersOnChange) {
    if (this.metadataService) {
      await this.metadataService.loadLearningGoals(this.methodSlug)
    }

    const url = Routing.generate('arrange_available_learning_items', { methodSlug: this.methodSlug })
    const response = await request(url)

    const items = MetadataTranslateService.process(
      flattenService.flattenMetadata(filterFields, response.data, this.metadataService !== null)
    );

    return ArrangementItems.createSearchList(
      ArrangementItems.withItemDisplayMetadata(items, itemMetadataKeys),
      filterFields,
      updateFiltersOnChange,
      this.metadataService
    )
  }

  async loadLearningItemChildren (forLearningItem, filterFields, itemMetadataKeys) {
    const url = Routing.generate('arrange_available_learning_item_children', {
      methodSlug: this.methodSlug,
      arrangementCode: forLearningItem.code,
      contentIdentifier: forLearningItem.contentIdentifier
    })
    const response = await request(url)

    // Flatten the filter fields until THIP fixes this
    const items = MetadataTranslateService.process(
      flattenService.flattenMetadata(filterFields, response.data)
    );

    return ArrangementItems.withItemDisplayMetadata(items, itemMetadataKeys)
  }

  async createArrangementFromArrangement (arrangementCode, newData, inAdminMode) {
    const route = inAdminMode ? 'arrange_create_predefined_arrangement_from_arrangement' : 'arrange_create_arrangement_from_arrangement'
    const { data } = await routePost(route, {
      methodSlug: this.methodSlug,
      arrangementCode: arrangementCode
    }, newData)
    return data.arrangementCode
  }

  async getGroups () {
    const { data } = await request(Routing.generate('arrange_get_groups', {
      methodSlug: this.methodSlug
    }))
    return data
  }
}

export default ArrangementService
