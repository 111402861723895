var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "notification",
      class: "notification--" + _vm.notification.type,
      attrs: { role: "alert" },
    },
    [
      _c(
        "div",
        { staticClass: "notification__label" },
        [
          _c("a-icon", {
            class: _vm.mapIcon(_vm.notification.icon),
            attrs: { name: _vm.mapIcon(_vm.notification.icon), size: "medium" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "notification__text",
        domProps: { innerHTML: _vm._s(_vm.notification.message) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }