var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "filter-option-link",
      class: { selected: _vm.isSelected, disabled: !_vm.isSelectable },
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          $event.preventDefault()
          return _vm.toggleFilter.apply(null, arguments)
        },
      },
    },
    [
      _vm._v(_vm._s(_vm.value.title) + "\n  "),
      _vm.isSelected
        ? _c("inline-svg", {
            staticClass: "pill-control",
            attrs: { svg: require("arrange/icon-close.svg") },
            on: { click: _vm.toggleFilter },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }