import {
  EventBus,
  NAVIGATE_EVENT,
  TOOL_USE_EVENT
} from './common/EventBus'

class GoogleAnalyticsService {
  constructor () {
    EventBus.$on(TOOL_USE_EVENT, (href, toolType, action = 'clicked') => {
      if (toolType) {
        gtag('event', action, {
          event_category: toolType
        });
      }
    })

    EventBus.$on(NAVIGATE_EVENT, (href, action = 'clicked') => {
      gtag('event', 'page_view', {
        page_path: href,
        page_title: document.title
      });
    })
  }
}

export class GoogleAnalyticsServiceFactory {
  static createGoogleAnalyticsService () {
    return new GoogleAnalyticsService()
  }
}
