var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c(
        "div",
        {
          staticClass:
            "flexbox flexbox--column flex-align-items--center flex-justify-content--center flex",
        },
        [
          _c(
            "a-loader",
            { attrs: { variant: _vm.forPage ? "page" : "default" } },
            [_vm._v(_vm._s(_vm.$t("generic.loading")))]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }