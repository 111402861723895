var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flexbox flexbox--column flex" },
    [
      _c("LoadingMessage", {
        attrs: { "for-page": true, loading: _vm.isLoading },
      }),
      _vm._v(" "),
      !_vm.isLoading
        ? [
            _c("detail-header", {
              attrs: {
                arrangement: _vm.arrangement,
                error: _vm.error,
                "is-saving": _vm.isSaving,
              },
              on: { "save-arrangement": _vm.checkSaveArrangement },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "arrangements-container px-3" }, [
              _c(
                "div",
                {
                  staticClass:
                    "arrangements-list__content flexbox flex-align-items--start justify-content-center",
                },
                [
                  _c("div", { staticClass: "arrangement-detail" }, [
                    _c(
                      "div",
                      { staticClass: "arrangement__columns-container flexbox" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "arrangement__column arrangement__column--search-filter",
                          },
                          [
                            _c("filter-controls"),
                            _vm._v(" "),
                            _c("filter-results"),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "arrangement__column arrangement__column--arrange py-3 pl-3",
                          },
                          [
                            _c(
                              "h3",
                              {
                                staticClass: "arrangement-column__header mb-0",
                              },
                              [_vm._v(_vm._s(_vm.arrangement.title))]
                            ),
                            _vm._v(" "),
                            _vm.arrangement.items
                              ? _c("arrangement-items")
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("modal-root"),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }