<template>
  <a-card class="learning-item" :class="{'learning-item--no-interaction': !hasInteraction, 'learning-item--draggable': draggable, 'learning-item--condensed': condensed, 'learning-item--unavailable': !isAvailable}" @click.prevent="showDetails">
    <div class="card-header" slot="header">
      <a-icon v-if="draggable" size="large" name="grip-vertical"></a-icon>
      <span class="card-header__title">{{ item.title }}</span>
      <template v-if="showAddRemove">
        <a-icon-button v-if="isInArrangement" class="card-header__icon-button" icon-size="small" variant="ghost" data-test="button.chapter.remove" icon="close" @click="moveToOtherList"></a-icon-button>
        <a-icon-button v-else icon-size="small"  class="card-header__icon-button" data-test="button.chapter.add" variant="ghost" icon="plus" @click="moveToOtherList"></a-icon-button>
      </template>
    </div>

    <div v-if="!condensed && hasMetadata" :data-test="learningItemDataTestSelector()">
        <div class="learning-item__metadata-container">
          <template v-for="(value, key, index) in item.displayMetadata">
            <span v-if="index > 0" class="learning-item__metadata-divider"/>
            <div class="learning-item__metadata" :data-test="key" :data-metadata-key="key" v-html="maxWords(value)"/>
          </template>
        </div>
    </div>

    <div v-if="showArrangeButton" class="flexbox flex-justify-content--end" slot="footer">
      <custom-button :icon="'shuffle'" :color="'blue'" :variant="'subtle'" size="tiny" icon-class="icon-shuffle" @click.native.stop="arrangeContent">
        {{ $t('generic.buttons.arrange') }}
      </custom-button>
    </div>
    
    <warning v-if="!isAvailable" :message="$t('fix_arrangement.unavailable')"/>
  </a-card>
</template>

<script>
const METADATA_MAX_WORDS = 16

import InlineSvg from '../../../components/InlineSvg'
import { EventBus, MODAL_OPEN_EVENT } from '../../../common/EventBus'
import { DetailModal } from '../modal/ModalContent'
import CustomButton from '../../user-interface/Button'
import Warning from '../../../components/notification/Warning'

export default {
  props: {
    item: Object,
    isInArrangement: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    },
    isAvailable: {
      type: Boolean,
      default: true
    },
    showArrangeButton: {
      type: Boolean,
      default: false
    },
    showAddRemove: {
      type: Boolean,
      default: true
    },
    allowDetails: {
      type: Boolean,
      default: true
    },
    condensed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hasMetadata: this.itemHasMetadata(),
      hasInteraction: this.allowDetails || this.draggable,
    }
  },

  components: {
    InlineSvg,
    CustomButton,
    Warning
  },

  methods: {
    itemHasMetadata() {
      return Object.keys(this.item.displayMetadata).length > 0;
    },
    moveToOtherList (event) {
      this.$emit('switch-list', this.item)
      event.stopPropagation()
    },
    learningItemDataTestSelector() {
      return `learning-item-${this.item.code}`
    },
    showDetails () {
      if (this.allowDetails) {
        EventBus.$emit(MODAL_OPEN_EVENT, DetailModal.learningItemDetail(this.item))
      }
    },
    maxWords (value) {
      if (undefined === value) {
        return 'undefined'
      }

      if (typeof value !== 'string' && undefined !== value.category) {
        value = value.category
      }

      const words = value.split(' ')
      if (words.length <= METADATA_MAX_WORDS) {
        return value
      }
      return words.slice(0, METADATA_MAX_WORDS).join(' ') + ' ...'
    },
    arrangeContent () {
      EventBus.$emit(MODAL_OPEN_EVENT, DetailModal.learningItemArrangeContent(this.item))
    }
  }
}
</script>
