var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.triggeredAction === null
      ? _c(
          "div",
          { staticClass: "modal-context-menu" },
          _vm._l(_vm.selectedActions, function (action) {
            return _c(
              "a",
              {
                staticClass: "modal-context-menu__item",
                attrs: { "data-test": action.cySelector },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.performAction(action)
                  },
                },
              },
              [_vm._v(_vm._s(action.label))]
            )
          }),
          0
        )
      : _c(
          "div",
          [
            _c(_vm.component, {
              tag: "component",
              attrs: { arrangement: _vm.arrangement },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }