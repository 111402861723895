var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.hasDescription
        ? _c("div", { staticClass: "pr-3 mb-3 a-modal-content" }, [
            _c("p", { staticClass: "feedback feedback--regular mb-0" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.learningItem.description) + "\n    "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "modal-button-footer",
        [
          _c(
            "a-button",
            { attrs: { color: "blue" }, on: { click: _vm.goToLearningItem } },
            [_vm._v(_vm._s(_vm.$t("generic.buttons.to_teaching_material")))]
          ),
          _vm._v(" "),
          _c(
            "a-button",
            {
              attrs: { color: "blue", variant: "ghost" },
              on: { click: _vm.close },
            },
            [_vm._v(_vm._s(_vm.$t("generic.buttons.close")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }