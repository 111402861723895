var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "pr-3 mb-3 a-modal-content" },
        [
          _vm.loading
            ? _c("loading-message", { attrs: { loading: true } })
            : _c("div", { staticClass: "a-modal-content__arrange-content" }, [
                _c(
                  "div",
                  { staticClass: "a-modal-content__arrange-content--title" },
                  [_vm._v(_vm._s(_vm.$t("generic.available")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "a-modal-content__arrange-content--title" },
                  [_vm._v(_vm._s(_vm.$t("generic.arrangement")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "a-modal-content__arrange-content--available",
                  },
                  [
                    _c(
                      "vue-draggable",
                      {
                        staticClass: "flex pr-1",
                        attrs: {
                          list: _vm.removedLearningItemChildren,
                          "ghost-class": "learning-item--dragging-placeholder",
                          draggable: ".learning-item--still-available",
                          group: {
                            name: "learningItemChildren",
                            put: false,
                            pull: "clone",
                          },
                          sort: false,
                        },
                      },
                      [
                        _c(
                          "transition-group",
                          {
                            staticClass: "learning-items-container",
                            class: {
                              "learning-items-container--empty":
                                _vm.removedLearningItemChildren.length === 0,
                            },
                            attrs: {
                              tag: "div",
                              "data-empty-items-message": _vm.$t(
                                "detail.available.empty_message"
                              ),
                              name: "direct",
                            },
                          },
                          _vm._l(
                            _vm.removedLearningItemChildren,
                            function (child) {
                              return _c("learning-item", {
                                key: "item-" + child.contentIdentifier,
                                staticClass: "learning-item--still-available",
                                attrs: {
                                  item: child,
                                  draggable: true,
                                  "allow-details": false,
                                  condensed: true,
                                },
                                on: { "switch-list": _vm.switchList },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "a-modal-content__arrange-content--arrangement",
                  },
                  [
                    _c(
                      "vue-draggable",
                      {
                        staticClass: "flex pr-1",
                        attrs: {
                          tag: "div",
                          draggable: ".learning-item",
                          list: _vm.learningItemChildren,
                          group: {
                            name: "learningItemChildren",
                            put: true,
                            pull: true,
                          },
                          "remove-on-spill": true,
                          "on-spill": _vm.onSpill,
                        },
                      },
                      [
                        _c(
                          "transition-group",
                          {
                            staticClass: "learning-items-container",
                            class: {
                              "learning-items-container--empty":
                                _vm.learningItemChildren.length === 0,
                            },
                            attrs: {
                              tag: "div",
                              "data-empty-items-message": _vm.$t(
                                "detail.items.empty_message"
                              ),
                              name: "direct",
                            },
                          },
                          _vm._l(_vm.learningItemChildren, function (child) {
                            return _c("learning-item", {
                              key: "child-item-" + child.contentIdentifier,
                              ref: "itemComponents",
                              refInFor: true,
                              attrs: {
                                item: child,
                                draggable: true,
                                "allow-details": false,
                                condensed: true,
                                "is-in-arrangement": true,
                              },
                              on: { "switch-list": _vm.switchList },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal-button-footer",
        [
          _c(
            "custom-button",
            {
              attrs: { size: "medium", color: "blue", disabled: _vm.loading },
              on: { click: _vm.applyToArrangement },
            },
            [_vm._v(_vm._s(_vm.$t("generic.buttons.confirm")))]
          ),
          _vm._v(" "),
          _c(
            "custom-button",
            {
              attrs: {
                size: "medium",
                color: "blue",
                variant: "ghost",
                disabled: _vm.loading,
              },
              on: { click: _vm.close },
            },
            [_vm._v(_vm._s(_vm.$t("generic.buttons.cancel")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }