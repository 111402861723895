<template>
  <a-button
    :size="size"
    :color="color"
    :variant="variant"
    :data-test="dataTest"
    @click="$emit('click', $event)">
    <a-icon v-if="icon" :name="icon" :color="color" />
    <slot v-if="$slots.default"/>
    <template v-else>{{ label }}</template>
  </a-button>
</template>

<script>
  export default {
    props: {
      icon: String,
      color: String,
      variant: String,
      label: String,
      dataTest: String,
      type: {
        type: String,
        default: 'primary'
      },
      size: {
        type: String,
        default: 'small'
      },
      loading: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
