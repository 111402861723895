var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showFilterPanel && _vm.initialized
    ? _c(
        "div",
        { staticClass: "arrangement-filter-panel-container" },
        [
          _c(
            "arrange-filter-panel",
            {
              attrs: {
                jsonData: _vm.filterCategoriesAsJson,
                "data-test": "filter-panel",
              },
              on: { filterChange: _vm.toggleFilter },
            },
            [
              _c(
                "a-button",
                {
                  staticStyle: { margin: "1rem" },
                  attrs: {
                    slot: "trigger",
                    "data-test": "filter-panel-button",
                  },
                  slot: "trigger",
                },
                [_vm._v("Open Filter")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm.initialized
    ? _c(
        "div",
        { staticClass: "arrangement-filters-accordion mt-2" },
        _vm._l(_vm.filterCategories, function (category, index) {
          return _c("filter-item", {
            key: index,
            attrs: {
              title: category.title,
              category: category.field,
              options: category.values,
            },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }