var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { ref: "arrangement-action", staticClass: "pr-3 mb-3 a-modal-content" },
        [
          _vm.saving ? _c("modal-loading") : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "fix-arrangement-container" },
            [
              _c("div", { staticClass: "fix-arrangement-header" }, [
                _c("span", { staticClass: "fix-arrangement-header__label" }, [
                  _vm._v(_vm._s(_vm.arrangement.title)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "fix-arrangement-description",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("fix_arrangement.description")),
                },
              }),
              _vm._v(" "),
              _c(
                "transition-group",
                {
                  ref: "itemsContainer",
                  staticClass:
                    "learning-items-container learning-items-container--with-replacements",
                  attrs: {
                    tag: "div",
                    "data-test": "learning-items-container",
                    name: "fade",
                  },
                },
                [
                  _vm._l(
                    _vm.arrangementStore.getInvalidItems(),
                    function (invalidItem) {
                      return [
                        _c("learning-item", {
                          key: "item-" + invalidItem.item.contentIdentifier,
                          attrs: {
                            item: invalidItem.item,
                            draggable: false,
                            "show-add-remove": false,
                            "allow-details": false,
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            key:
                              "item-replacement-icon" +
                              invalidItem.item.contentIdentifier,
                            staticClass: "learning-items-container__icon",
                          },
                          [
                            _c("a-icon", {
                              staticClass: "replacement-icon",
                              attrs: { name: "arrow-right" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        null !== invalidItem.replacement
                          ? _c("learning-item", {
                              key:
                                "item-replacement-" +
                                invalidItem.item.contentIdentifier,
                              attrs: {
                                item: invalidItem.replacement,
                                draggable: false,
                                "show-add-remove": false,
                                "allow-details": false,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        null === invalidItem.replacement
                          ? _c("no-replacement-item", {
                              key:
                                "item-replacement-" +
                                invalidItem.item.contentIdentifier,
                            })
                          : _vm._e(),
                      ]
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal-button-footer",
        [
          _c(
            "a-button",
            {
              attrs: {
                "data-test": "fix-arrangement-form.button.submit",
                color: "blue",
                disabled: _vm.saving,
              },
              on: { click: _vm.fixArrangement },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("generic.buttons.fix_arrangement")) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "a-button",
            {
              attrs: {
                "data-test": "fix-arrangement-form.button.cancel",
                color: "blue",
                variant: "ghost",
                disabled: _vm.saving,
              },
              on: { click: _vm.cancel },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("generic.buttons.cancel")) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }