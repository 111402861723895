<template>
  <div class="flexbox flexbox--column flex">
      <!-- CUSTOM HEADER -->
    <custom-header :section-title="$route.meta.title">

      <!-- BUTTONS SLOT -->
      <div class="section-header__button-container" slot="buttons">
        <a-button
          v-if="this.store.hasAdminLicense"
          @click="switchRole()"
          variant="subtle"
          color="blue"
          size="medium"
          :data-test="'button.switch-role'"
        >
          {{ this.store.inAdminMode ? this.$i18n.t('generic.buttons.switch.to_instructor') : this.$i18n.t('generic.buttons.switch.to_admin') }}
        </a-button>
      </div> <!-- BUTTONS SLOT END -->
    </custom-header> <!-- CUSTOM HEADER END -->

    <LoadingMessage :loading="isLoading"/>
    <div class="methods-container px-3" v-if="!isLoading">
      <warning :message="'Error: You are not in admin mode.'" v-if="!this.store.inAdminMode"/>

      <span v-if="this.store.inAdminMode">
        <h1 class="h3">{{ this.$i18n.t('generic.arrangeable_methods') }}</h1>
        <table class="table table--full-width table--bordered table--condensed" >
          <thead>
          <tr>
            <th>Methode</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="method in this.store.methods">
              <td>
                <a :href="getUrl(method)">{{ method.name }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </span>
    </div>

  </div>
</template>

<script>
import InlineSvg from '../../components/InlineSvg'
import { detailStore, store } from '../../store'
import DetailHeader from './detail/DetailHeader'
import Dropdown from '../../components/Dropdown'
import ArrangementItems from './detail/ArrangementItems'
import ModalRoot from './modal/ModalRoot'
import LoadingMessage from './detail/LoadingMessage'
import FilterControls from './detail/filters/FilterControls'
import FilterResults from './detail/filters/Results'
import Warning from '../../components/notification/Warning'
import CustomHeader from "./Header.vue";

export default {
  props: {
    code: String
  },

  components: {
    CustomHeader,
    Warning,
    LoadingMessage,
    InlineSvg,
    ArrangementItems,
    Dropdown,
    ModalRoot,
    DetailHeader,
    FilterControls,
    FilterResults
  },

  data () {
    return {
      isLoading: true,
      error: null,
      store,
      detailStore
    }
  },

  created () {
    detailStore.collapsed = false
  },

  mounted () {
    this.getData()
  },

  computed: {
    baseUrl () {
      return window.location.origin
    },
  },
  methods: {
    switchRole() {
      this.store.switchRole();
      this.$router.push('/');
    },
    getUrl(method) {
      return this.baseUrl + '/' + method.slug
    },
    async getData () {
      this.isLoading = true
      await store.loadMethods()
      this.isLoading = false
    },
  }
}
</script>
