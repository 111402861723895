<template>
  <a class="filter-option-link"
     :class="{ 'selected' : isSelected, 'disabled' : !isSelectable }"
     @click.self.prevent="toggleFilter">{{ value.title }}
    <inline-svg v-if="isSelected" class="pill-control" @click="toggleFilter" :svg="require('arrange/icon-close.svg')"/>
  </a>
</template>

<script>
  import { store } from '../../../../store'
  import InlineSvg from '../../../../components/InlineSvg'

  export default {
    props: {
      value: Object,
      category: String
    },

    computed: {
      isSelected() {
        return store.arrangementItems.filterIsActiveAndHasValue(this.category, this.value.value)
      },
      isSelectable() {
        return !this.isSelected
      }
    },

    components: {
      InlineSvg
    },

    methods: {
      toggleFilter () {
        if (this.isSelected) {
          store.arrangementItems.removeFilterValue(this.category, this.value.value)
          return
        }

        gtag('event', 'search', {
          search_term: this.value,
        });

        store.arrangementItems.filterOn(this.category, this.value)
      }
    }
  }
</script>
