<template>
  <div class="notification" :class="'notification--' + notification.type" role="alert">
    <div class="notification__label">
      <a-icon :name="mapIcon(notification.icon)" size="medium" :class="mapIcon(notification.icon)"/>
    </div>
    <div class="notification__text" v-html="notification.message"></div>
  </div>
</template>

<script>
  import Notification from './Notification'

  const iconMap = {
    'icon-alert': 'warning',
    'icon-info': 'info',
    'icon-lock icon--grey': 'lock'
  };

  export default {
    props: {
      notification: {
        type: Notification
      },
    },
    methods: {
      mapIcon(icon) {
        if (iconMap.hasOwnProperty(icon)) {
          return iconMap[icon];
        }
        return icon;
      }
    }
  }
</script>
