export const locales = {
  nl: {
    generic: {
      arrangement: 'Arrangement',
      arrangement_detail: 'Arrangement detail',
      available: 'Beschikbaar',
      buttons: {
        cancel: 'Annuleren',
        close: 'Sluiten',
        connect: 'Koppelen',
        confirm: 'Opslaan',
        create_arrangement: 'Arrangement aanmaken',
        fix_arrangement: 'Arrangement repareren',
        delete: 'Verwijderen',
        duplicate: 'Dupliceren',
        new_arrangement: 'Nieuw arrangement',
        switch: {
          to_admin: 'Arrangeer als admin',
          to_instructor: 'Arrangeer als docent'
        },
        remove: 'Verwijderen',
        dont_save: 'Niet opslaan',
        save: 'Opslaan',
        to_teaching_material: 'Naar lesstof',
        arrange: 'Arrangeren'
      },
      context_menu: {
        connect: {
          label: 'Koppelen'
        },
        delete: {
          label: 'Verwijderen'
        },
        duplicate: {
          label: 'Dupliceren'
        },
        edit: {
          label: 'Naam en omschrijving wijzigen'
        }
      },
      dropdowns: {
        sorting: {
          placeholder: 'Sorteren op...'
        }
      },
      help_messages: {
        connect_not_yet_connected: '\'{{ arrangement }}\' is nog niet gekoppeld aan een groep. Zodra je een arrangement koppelt is het zichtbaar voor de gekoppelde groepen.'
      },
      input: {
        search: {
          placeholder: 'Zoeken'
        }
      },
      loading: 'De data wordt ingeladen...',
      modal: {
        messages: {
          select_group: 'Selecteer een groep',
          manage_groups: 'Beheer hier de gekoppelde groepen van \'{{ arrangement }}\'',
          connect_not_yet_connected: '\'{{ arrangement }}\' is nog niet gekoppeld aan een groep. Zodra je een arrangement koppelt is het zichtbaar voor de gekoppelde groepen.',
          delete_arrangement: 'Weet je zeker dat je \'{{ arrangement }}\' wilt verwijderen?',
          delete_arrangement_warning: 'Let op! Met deze actie wordt het arrangement permanent verwijderd! Wijzigingen in het arrangement zijn niet meer mogelijk en bij de leerling zal het arrangement niet meer actief getoond worden.',
          lose_changes: 'Dit arrangement is nog niet opgeslagen. Als je terug gaat zonder op te slaan verlies je mogelijk je voortgang.',
          lose_changes_no_items: 'Dit is een leeg arrangement en kan daarom niet worden opgeslagen.',
          confirm_duplicate: 'Weet je zeker dat je arrangement \'{{ arrangement }}\' wil dupliceren?',
          confirm_save_not_your_arrangement: 'Let op! Dit arrangement is origineel niet door jou gemaakt. Weet je zeker dat je de wijzigingen op wil slaan?',
          confirm_delete_not_your_arrangement: 'Let op! Dit arrangement is origineel niet door jou gemaakt. Weet je zeker dat je het arrangement wil verwijderen?',
          empty_title: 'Je dient een titel in te vullen',
          no_replacement_item: 'Er is geen vervangend onderdeel gevonden'
        },
        titles: {
          lose_changes: 'Niet opgeslagen',
          learning_item_detail: 'Leereenheid',
          not_your_arrangement: 'Niet jouw arrangement'
        }
      },
      arrangeable_methods: 'Arrangeable methods',
      my_arrangements: 'Mijn arrangementen',
      new_arrangement: 'Nieuw arrangement',
      fix_arrangement: 'Arrangement repareren',
      switch_role: 'Verander rol',
      new_arrangement_title: 'Stel nieuw arrangement samen',
      new_arrangement_subtitle: 'Start je eerste arrangement!',
      no_arrangements: 'Je hebt nog geen arrangementen',
      updated_at: 'Laatst bewerkt op {{ date, DD-MM-YYYY }} om {{ time, mm:ss }}'
    },
    fix_arrangement: {
      unavailable: 'Dit onderdeel is niet meer beschikbaar',
      description: '<p>Dit arrangement bevat onderdelen die niet meer beschikbaar zijn. Als je dit arrangement wil opslaan dienen deze onderdelen vervangen of verwijderd te worden.</p><p>In het overzicht hieronder zie je of er een vervangend onderdeel beschikbaar is, of dat het onderdeel verwijderd dient te worden.</p><p></p>'
    },
    new_arrangement: {
      template: {
        empty: {
          title: 'Leeg arrangement',
          subtitle_empty: 'Start vanuit een volledig leeg arrangement',
          subtitle_predefined: 'Gebruik dit als basis voor een nieuw arrangement.'
        }
      }
    },
    detail: {
      search: {
        search_filter_header: 'Zoeken & filteren',
        search_header: 'Zoeken',
        filter_header: 'Filteren',
        results_header: 'Beschikbare leereenheden',
        no_filters_available: 'Geen filters beschikbaar.'
      },
      items: {
        empty_message: 'Je arrangement is nog leeg. Voeg lesstof toe aan je arrangement door het hier naartoe te slepen.'
      },
      available: {
        empty_message: 'Alle lesstof zit in het arrangement. Verwijder lesstof uit je arrangement door het hier naartoe te slepen.'
      }
    },
    list: {
      clear_filters: 'Wis filters',
      no_search_results: 'Niks gevonden met je huidige filters.',
      no_arrangements: 'Je hebt nog geen arrangement. Klik rechtsboven om een nieuw arrangement aan te maken.',
      scroll_load_more: 'Scroll (of klik hier) om meer items te tonen',
      number_of_search_results: '{{ current }} van {{ total }} resultaten',
      total_search_results: '{{ total }} resultaten',
      item: {
        connected_groups_label: 'Gekoppeld aan:',
        owner: 'Eigenaar: {{ owner }}',
        last_modified_by: 'Laatst bewerkt door: {{ lastModifiedBy }}'
      }
    },
    placeholders: {
      new_name_label: 'Voeg hier de titel toe',
      new_name_description: 'Voeg een omschrijving toe (optioneel)'
    },
    connect: {
      no_groups_selected: 'Geen groepen geselecteerd.',
      groups_selected: '{{ selected }} van {{ total }} groepen geselecteerd:',
      confirm_remove_connected_group: 'Let op! Met deze actie is het arrangement niet meer bereikbaar voor de student! Door studenten behaalde resultaten zullen door student en ook docent niet meer inzichtelijk zijn.'
    },
    filters: {
      smartMetadata: {
        mboQualifications: 'Kwalificatiedossier',
        mboCourseStructure: 'Opbouw kwalificatiedossier',
        mboTechnical: 'Domein',
        mboTouchTech: 'Vakgebied',
        mboDoorEngels: 'Thema',
        mboDoorNederlands: 'Thema',
        mboWorkProcesses: 'Kerntaak en werkproces',
        mboSubjectArea: 'Vakgebied',
        mboProfile: 'Profiel',
        learningGoals: 'Leerdoel',
        rhvClassifications: 'Differentiatie',
        themes: 'Thema\'s',
        musicDifficulty: 'Muziekmoeilijkheidsgraad',
        musicStyles: 'Trefwoord',
        keywords: 'Trefwoorden'
      },
      streamMetadata: {
        schoolYears: 'Leerjaar',
      },
      navigationMetadata: {
        number: 'Taak'
      },
      learningMetadata: {
        language: 'Taal',
        cefrQualifications: 'ERK-niveau',
        workingMethod: 'Werkvorm',
        referenceLevel: 'Referentieniveau',
        learningLevels: 'Leerniveau'
      }
    },
    sorting: {
      sortOn: 'Sorteer op..'
    },
    metadataValues: {
      'learningMetadata.language': {
        'nl-NL': 'Nederlands',
        'en-GB': 'Engels',
        'fr-FR': 'Frans',
        'de-DE': 'Duits',
        'nl': 'Nederlands',
        'en': 'Engels',
        'fr': 'Frans',
        'de': 'Duits'
      }
    }
  }
}
