import { routeGet } from '../util/request'

class MethodService {

  async loadMethods () {
    const response = await routeGet('arrange_predefined_methods')

    return response.data;
  }
}

export default MethodService
