var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-header", { attrs: { title: _vm.title, error: _vm.error } }, [
    _c(
      "div",
      {
        staticClass: "section-header__button-container",
        attrs: { slot: "buttons" },
        slot: "buttons",
      },
      [
        _c(
          "a-button",
          {
            attrs: {
              disabled: !_vm.canSaveArrangement,
              "data-test": "button.save",
              color: _vm.saveButtonColor,
            },
            on: {
              click: function ($event) {
                return _vm.$emit("save-arrangement")
              },
            },
          },
          [
            _c("a-icon", { attrs: { name: "save" } }),
            _vm._v(
              "\n      " + _vm._s(_vm.$t("generic.buttons.save")) + "\n    "
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("custom-button", {
          attrs: {
            size: "default",
            color: "blue",
            icon: "group",
            variant: "subtle",
            label: _vm.$t("generic.buttons.connect"),
            disabled: !_vm.canLinkArrangement,
            "data-test": "button.connect",
          },
          on: { click: _vm.openConnectModal },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }