var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.component !== null
    ? _c(
        "div",
        [
          _c(
            "a-content-modal",
            {
              key: Date.now(),
              ref: "contentmodal",
              on: { close: _vm.handleContentModalClose },
            },
            [
              _c(
                "div",
                { staticClass: "a-content-modal-title" },
                [
                  _vm.icon !== null
                    ? _c("a-icon", {
                        attrs: { size: "large", name: _vm.icon.file },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.title))]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                _vm.component,
                _vm._b(
                  { tag: "component", on: { close: _vm.handleClose } },
                  "component",
                  _vm.props,
                  false
                )
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }